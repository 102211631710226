<template>
  <v-card elevation="0">
    <v-card-title class="d-flex justify-space-between">
      <span>{{ $t('DTO endorsement') }}</span>
      <NewEndorsement @done="fetch"/>
    </v-card-title>
    <v-divider />
    <v-data-table
        :headers="headers"
        :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
        :items="filteredItemsTable"
        :search="filters.search"
        :loading="loading"
        class="striped"
        sort-by="id"
        :sort-desc="true"
      >
      <!-- Filtro -->
      <template #top>
        <v-row no-gutters>
            <v-col cols="10">
                <v-text-field v-model="filters.search"
                    :label="$t('Search')"
                    :placeholder="$t('Search by from/to organization, amount or validity')"
                    append-icon="mdi-magnify"
                    filled dense hide-details clearable />
            </v-col>        
            <v-col cols="2">
                <v-select v-model="filters.status" :items="itemsStatus" :label="$t('Status')" filled dense hide-details clearable>
                    <template #selection="{ item }">
                        {{ item }}
                    </template>
                </v-select>
            </v-col>    
        </v-row>
      </template>
      <!-- Personalizar columna tabla -->
      <template v-slot:header.validity="{ header }">
        <div class="text-center text-no-wrap">{{header.text}}</div>
      </template>
      <template v-slot:item.validity="{ item }">
        <div class="text-center text-no-wrap">{{getYear(item.validity)}}</div>
      </template>
      <template v-slot:header.status="{ header }">
        <div class="text-center text-no-wrap">{{header.text}}</div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          class="ma-2"
          :color="getColor(item.status)"
          @click="changeStatus(item)"
          text-color="white"
          :disabled="isDisabled(item)"
        >
          <span class="text-center text-no-wrap">{{getValueStatus(item.status)}}</span>
        </v-chip>
      </template>
      <template #[`footer.prepend`]>
        <v-btn small color="secondary" icon @click="fetch"><v-icon small>mdi-refresh</v-icon></v-btn>
      </template>
    </v-data-table>

    <DialogStatus @done="fetch"
      :key="keyDialogStatus"
      :show_dialog="show_status_dialog"
      :data = "itemSelect"
      v-on:action="runAction"
      v-on:close-status-dialog="closeStatusDialog"
    ></DialogStatus>
  </v-card>
  
</template>

<script>
import NewEndorsement from '../components/endorsement/NewEndorsement.vue'
import DialogStatus from '../components/endorsement/DialogStatus.vue'

export default {
  name: "DtoEndorsement",
  components: {
    NewEndorsement, DialogStatus
  },
  data: function() {
    return {
      items: [],
      itemSelect: {
        id: null,
        from: null,
        to: null,
        amount: null,
        status: null,
        lb_approved_by: null,
        received_by: null,
        rejected_by: null,
        request_timestamp: null,
        received_timestamp: null,
        rejected_timestamp: null,
        action: null,
        validity: null
      },
      headers: [
        { value: 'id', text: '#', align: 'center', sortable: true, divider: true, width: 80, },
        { value: 'from_organization', text: this.$i18n.t('From organization'), align: 'left', sortable: true },
        { value: 'to_organization', text: this.$i18n.t('To organization'), align: 'left', sortable: true },
        { value: 'amount', text: this.$i18n.t('Amount'), align: 'center', sortable: false },
        { value: 'validity', text: this.$i18n.t('Validity'), align: 'right', sortable: false },
        { value: 'status', text: this.$i18n.t('Status'), align: 'center', sortable: false },
      ],
      loading: false,
      filters: {
        search: null,
        status: null,
        from: null,
        to: null,
      },
      listStatus: [],
      itemsStatus: [],
      totalItems: 0,
      itemsPerPage: 0,
      options: {},
      myOrganization: null,

      show_status_dialog: false,
      counter: 0,
    }
  },
  computed: {
    keyDialogStatus: function() {
      return "dialogStatus" + this.counter.toString();
    },
    filteredItemsTable(){
      return this.getFilteredItems;
    },
    getFilteredItems(){
      // Ningun filtro aplicado
      if (this.filters.search===null && this.filters.status===null && this.filters.from===null && this.filters.to===null) {
        return this.items;
      }
      var filterStatus = !this.filters.status? null : this.filters.status.trim().toLowerCase();
      var filterSearch = !this.filters.search? null : this.filters.search.trim().toLowerCase();

      return this.items.filter(item => {
        const statusMatch = !filterSearch || this.getValueStatus(item.status).trim().toLowerCase().includes(filterSearch);
        const validityMatch = !filterSearch || "".concat(item.validity).trim().toLowerCase().includes(filterSearch);
        const amountMatch = !filterSearch || "".concat(item.amount).trim().toLowerCase().includes(filterSearch);
        const from = !filterSearch || "".concat(item.from_organization).trim().toLowerCase().includes(filterSearch);
        const to = !filterSearch || "".concat(item.to_organization).trim().toLowerCase().includes(filterSearch);
        return (statusMatch || amountMatch || from || to || validityMatch) &&
            (!filterStatus || this.getValueStatus(item.status).trim().toLowerCase().includes(filterStatus));
      });
    },
    
  },
  mounted() {
    this.getMyOrganization();
    this.fetch();
    this.fetchStatusEndorsement();
  },
  methods: {
    isAdministratorUser(){
      return this.$store.getters['auth/isAdmin'];
    },
    getMyOrganization(){
      this.$apiRequest('get', 'organizations/admin/', {}).then(res=> {
        this.myOrganization = res.data.name;
      })
    },
    fetch(){
      this.loading = true;

      if(this.isAdministratorUser()){
        this.$apiRequest('get', 'endorsement-request/', {}).then(res=> {
          this.items = res.data;
          this.loading = false;
        })
      }else{
        this.$apiRequest('get', 'endorsement-request/organization/', {}).then(res=> {
          this.items = res.data;
          this.loading = false;
        })
      }
    },
    getYear(date) {
      const dateObject = new Date(date);
      return dateObject.getFullYear();
    },
    fetchStatusEndorsement(){
      return this.$apiRequest('get', 'endorsement-request/status/').then((res) => {
        if(typeof res != 'undefined'){
          res.data.map(item => {
            var aux = new Object();
            aux.key = item[0];
            aux.value = item[1];
            this.listStatus.push(aux)
          })
          this.listStatus = this.listStatus.sort(function(a, b) {
            return a.value.localeCompare(b.value);
          });
          this.itemsStatus = this.listStatus.map(item => item.value);
        }
      })
    },
    getValueStatus(key){
      return (!!key && typeof key != 'undefined' )? this.listStatus.filter(item => item.key===key).map(item => item.value).reduce((accum, currVal) => accum+ currVal, "") : "";
    },
    getColor(status){
      switch (status) {
        case "pending":
          return "orange";
        case "lb_approved":
          if(this.$store.getters['auth/isAdmin']){//Si son usuarios admin de Light Bridges
            return "blue";
          }else{
            return "orange";
          }
        case "received":
          return "blue";
        case "rejected":
          return "red";
        default:
          return "orange";
      }
    },
    changeStatus(item){
      this.itemSelect.id = item.id;
      this.itemSelect.from = item.from_organization;
      this.itemSelect.to = item.to_organization;
      this.itemSelect.amount = item.amount;
      this.itemSelect.status = item.status;
      this.itemSelect.validity = item.validity;

      this.itemSelect.lb_approved_by = item.lb_approved_by;
      this.itemSelect.received_by = item.received_by;
      this.itemSelect.rejected_by = item.rejected_by;
      this.itemSelect.request_timestamp = item.request_timestamp;
      this.itemSelect.received_timestamp = item.received_timestamp;
      this.itemSelect.rejected_timestamp = item.rejected_timestamp;
      this.showStatusDialog();
    },
    getCounter() {
      return this.counter++;
    },
    showStatusDialog() {
      this.show_status_dialog = true;
    },
    closeStatusDialog() {
      // this.titleConfirm = "";
      // this.messageConfirm = "";
      this.show_status_dialog = false;
    },
    runAction(action) {
      console.log("runAction: ", action);
      
    },
    isDisabled(item){
      if(item.status === "pending"){
        if(this.isAdministratorUser()){
          return false;
        }else{
          return true;
        }
      }
      if(item.status === "lb_approved"){
        if(this.isAdministratorUser()){
          false;
        }else{
          return item.from_organization === this.myOrganization;
        }
      }
      return false;
    },
  },
  watch: {
    
  },
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
