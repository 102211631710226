<template>
    <v-card class="mt-3" flat>
      <!-- Tabla -->
      <v-data-table
        :headers="headers"
        :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
        :items="filteredItemsTable"
        :search="filters.search"
        class="striped"
      >
        <!-- Filtro -->
        <template #top>
          <v-row no-gutters>
              <v-col cols="4">
                  <v-text-field v-model="filters.search"
                      :label="$t('Search')"
                      :placeholder="$t('Search by ID, proposal name, type or amount')"
                      append-icon="mdi-magnify"
                      filled dense hide-details clearable />
              </v-col>    
              <v-col cols="3">
                  <v-datetime-picker date-format="yyyy-MM-dd" time-format="HH:mm" :label="$t('Since')" v-model="filters.from"
                          :text-field-props="{ hideDetails: true, filled: true, dense: true, appendIcon: 'mdi-calendar' }"
                          :date-picker-props="{ noTitle: true, }"
                          :time-picker-props="{ noTitle: true, format: '24hr' }"
                      >
                      <template #dateIcon><v-icon>mdi-calendar</v-icon></template>
                      <template #timeIcon><v-icon>mdi-clock</v-icon></template>
                      <template #actions="{ parent }">
                        <v-btn text color="secondary" @click.native="parent.clearHandler">{{ $t('Clear') }}</v-btn>
                        <v-btn text color="primary" @click="parent.okHandler">{{ $t('Ok') }}</v-btn>
                      </template>
                  </v-datetime-picker>
              </v-col>    
              <v-col cols="3">
                  <v-datetime-picker date-format="yyyy-MM-dd" time-format="HH:mm" :label="$t('Until')" v-model="filters.to"
                          :text-field-props="{ hideDetails: true,  filled: true, dense: true, appendIcon: 'mdi-calendar' }"
                          :date-picker-props="{ noTitle: true, }"
                          :time-picker-props="{ noTitle: true, format: '24hr' }"
                      >
                      <template #dateIcon><v-icon>mdi-calendar</v-icon></template>
                      <template #timeIcon><v-icon>mdi-clock</v-icon></template>
                      <template #actions="{ parent }">
                      <v-btn text color="secondary" @click.native="parent.clearHandler">{{ $t('Clear') }}</v-btn>
                      <v-btn text color="primary" @click="parent.okHandler">{{ $t('Ok') }}</v-btn>
                      </template>
                  </v-datetime-picker>
              </v-col>    
              <v-col cols="2">
                  <v-select v-model="filters.type" :items="itemType" :label="$t('Type')" filled dense hide-details clearable>
                      <template #selection="{ item }">
                          {{ item }}
                      </template>
                  </v-select>
              </v-col>    
          </v-row>
        </template>
        <!-- Personalizar columna tabla -->
        <template v-slot:header.date="{ header }">
          <div class="text-center text-no-wrap">{{header.text}}</div>
        </template>
        <template v-slot:item.date="{ item }">
          <div class="text-center text-no-wrap">{{formatDate(item.date)}}</div>
        </template>

        <template v-slot:header.type="{ header }">
          <div class="text-center text-no-wrap">{{header.text}}</div>
        </template>
        <template v-slot:item.type="{ item }">
          <div class="text-center text-no-wrap">{{capitalizeFirstLetter(item.type)}}</div>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>


export default {
  name: "AuditTable",
  components: {  },
  props: {
    items: {
      type: Array,
    },
  },
  data: function() {
    return {
      headers: [
        { value: 'date', text: this.$i18n.t('Date'), align: 'center', sortable: true },
        { value: 'proposal_id', text: '#', align: 'center', sortable: false,  width: 80, },
        { value: 'title', text: this.$i18n.t('Proposal Name'), align: 'center', sortable: true },
        { value: 'type', text: this.$i18n.t('Type'), align: 'center', sortable: true },
        { value: 'amount', text: this.$i18n.t('Amount'), align: 'center', sortable: true },
        
      ],
      loading: false,
      filters: {
        search: null,
        type: null,
        from: null,
        to: null,
      },
      itemType: ["Assignment", "Consumption", "Locked"],
    }
  },
  computed: {
    filteredItemsTable(){
      return this.getFilteredItems;
    },
    getFilteredItems(){
      // Ningun filtro aplicado
      if (this.filters.search===null && this.filters.type===null && this.filters.from===null && this.filters.to===null) {
        return this.items;
      }
      var filterType = !this.filters.type? null : this.filters.type.trim().toLowerCase();
      var filterFrom = !this.filters.from? null : new Date(this.filters.from);
      var filterTo = !this.filters.to? null : new Date(this.filters.to);
      var filterSearch = !this.filters.search? null : this.filters.search.trim().toLowerCase();
      // Si fecha de inicio y final estan seteadas
      if(this.filters.from!==null && this.filters.to!==null){
        return this.items.filter(item => {
          const titleMatch = !filterSearch || item.title.toLowerCase().includes(filterSearch);
          const typeMatch = !filterSearch || item.type.trim().toLowerCase().includes(filterSearch);
          const proposalIdMatch = !filterSearch || "".concat(item.proposal_id).trim().toLowerCase().includes(filterSearch);
          const amountMatch = !filterSearch || "".concat(item.amount).trim().toLowerCase().includes(filterSearch);
          return (titleMatch || typeMatch || proposalIdMatch || amountMatch) &&
              (!filterType || item.type.trim().toLowerCase().includes(filterType)) &&
              ( (!filterFrom && !filterTo) || new Date(item.date)>=filterFrom && new Date(item.date)<=filterTo);
        });
      }else{
        return this.items.filter(item => {
          const titleMatch = !filterSearch || item.title.toLowerCase().includes(filterSearch);
          const typeMatch = !filterSearch || item.type.trim().toLowerCase().includes(filterSearch);
          const proposalIdMatch = !filterSearch || "".concat(item.proposal_id).trim().toLowerCase().includes(filterSearch);
          const amountMatch = !filterSearch || "".concat(item.amount).trim().toLowerCase().includes(filterSearch);
          
          return (titleMatch || typeMatch || proposalIdMatch || amountMatch) &&
              (!filterType || item.type.trim().toLowerCase().includes(filterType)) &&
              (!filterFrom || new Date(item.date)>=filterFrom) &&
              (!filterTo || new Date(item.date)<=filterTo); 
        });
      }
    },
  },
  mounted() {
    // console.log("[abe] - registros Tabla Audit: ",this.items);
  },
  methods: {
    formatDate(date) {
      if (date ) {
        const dateObject = new Date(date);
        const mes = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        const dia = dateObject.getDate().toString().padStart(2, "0");

        const horas = dateObject.getHours().toString().padStart(2, "0");
        const minutos = dateObject.getMinutes().toString().padStart(2, "0");
        const segundos = dateObject.getSeconds().toString().padStart(2, "0");

        return dateObject.getFullYear()+"-"+mes+"-"+dia+" "+horas+":"+minutos+":"+segundos;
      } else {
        return null;
      }
    },
    capitalizeFirstLetter(word) {
      if(typeof word!=='undefined'){
        return word.charAt(0).toUpperCase() + word.slice(1);
      }else{
        return word;
      }
    },
    
  },
  watch: {
    
  },
  
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
</style>
