<template>
  <v-dialog v-model="dialog" width="800">
    <template #activator="{ on, attrs }">
      <v-btn color="primary" text outlined v-bind="attrs" v-on="on">{{ $t("New proposal") }}</v-btn>
    </template>
    <v-card :loading="loading">
      <v-form lazy-validation ref="formProposal">
        <v-card-title class="text-h5 mb-5">{{ $t("New proposal") }}</v-card-title>
        <v-card-text>
          <v-text-field 
            :label="$t('Title')"
            v-model="form.title" 
            outlined
            :rules="requieredRule"/>
          <v-textarea 
            :label="$t('Justification')" 
            v-model="form.justification" 
            :placeholder="$t('Max. 300 words')" 
            outlined 
            :rules="requieredRule"/>
          <v-select outlined :label="$t('Organization')" 
            v-model="form.organization" 
            :items="organizationsChoices" 
            item-text="name" 
            item-value="id" 
            :rules="requieredRule"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false">{{ $t('Close') }}</v-btn>
          <v-btn color="primary" text @click="submit">{{ $t('Create') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewProposal",
  data: function() {
    return {
      dialog: false,
      loading: false,
      organizationsChoices: null,
      form: {
        title: null,
        justification: null,
        total_requested_time: 0,
        organization: null,
      },
      requieredRule: [],
    }
  },
  mounted() {
    this.fetchMineOrganizations();
    this.activateRules();
  },
  methods: {
    submit() {
      if (this.$refs.formProposal && this.$refs.formProposal.validate()) {
        this.loading = true;
        this.$apiRequest('post', "proposals/", { 
          data: this.form,
        }).then(() => {
          this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("Proposal succesfully created")
          });
          this.loading = false;
          this.dialog = false;
          this.resetForm();
          this.$emit("done");
        })
      }
    },
    fetchMineOrganizations() {
      this.loading = true;
      this.$apiRequest('get', 'organizations/mine/').then((res) => {
        this.loading = false;
        this.organizationsChoices = res.data;
      })
    },
    activateRules() {
      let auxRules = [];
      let required = v => v != null || this.$t("The field is required");
      
      auxRules.push(required);
      
      Object.assign(this.requieredRule, auxRules);
    },
    resetForm(){
      this.$refs.formProposal.reset();
      // this.form.title = null;
      // this.form.justification = null;
      // this.form.organization = null;
    },
  },
}
</script>

<style scoped>
</style>
