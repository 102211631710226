<template>
  <v-card :elevation="0">
    <v-row>
      <v-col cols="9">
        <v-btn outlined color="primary" class="mx-1">{{ $t('Delete pending blocks') }}</v-btn>
      </v-col>
      <v-col cols="3">
        <div class="d-flex flex-row-reverse align-center">
          <v-btn class="mx-1" icon @click="fetch(false)"><v-icon>mdi-refresh</v-icon></v-btn>
          <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="shrink" dense filled
              max-width="300" v-model="form.date" append-icon="mdi-calendar"
              hide-details readonly v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker v-model="form.date" @input="dateMenu = false" />
          </v-menu>
          <div class="pa-3" v-if="loading">
            <span>{{ $t("Loading..") }}</span>
            <v-progress-circular :size="20" indeterminate />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <div class="timeline mx-1">
              <timeline ref="timeline"
                :items="timelineItems" :groups="telescopes"
                :options="timelineOptions"
                @click="handleTimelineClick"
                @select="handleTimelineSelect"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="primary lighten-5 my-3 mx-1" :loading="blockLoading" min-height="200">
              <div class="d-flex align-center" style="height: 300px" v-if="!selectedBlock">
                <v-card-text class="text-center font-italic">
                  {{ $t('Select any block in the timeline to see more details here') }}
                </v-card-text>
              </div>
              <v-card-text v-else>
                <div class="d-flex justify-space-between">
                    <div class="text-h4 font-weight-thin">
                      OB{{ selectedBlock.id }} | {{ selectedBlock.proposal }}
                      <v-chip class="mx-1">SciMerit {{ selectedBlock.scientific_merit }}</v-chip>
                      <v-chip class="mx-1">EcoMerit {{ selectedBlock.economic_merit }}</v-chip>
                      <v-btn icon class="ml-1"
                        :title="$t('Go to proposal details page')"
                        :to="{ name: 'proposal-details', params: { id: selectedBlock.proposal_id }}"
                      ><v-icon>mdi-location-enter</v-icon></v-btn>
                    </div>
                    <div>
                      <status-chip :value="selectedBlock.status" kind="observing-block" />
                    </div>
                </div>
                <v-divider class="my-3" />
                <div>
                    <span class="mr-5"><span class="details-label">{{ $t('Observing Run') }}:</span>{{ selectedBlock.run }}</span>
                    <span class="mr-5"><span class="details-label">{{ $t('Target') }}:</span>{{ selectedBlock.target }}</span>
                    <span class="mr-5"><span class="details-label">{{ $t('RA') }}:</span>{{ selectedBlock.ra|decimal2sexagesimal(false) }}h</span>
                    <span class="mr-5"><span class="details-label">{{ $t('DEC') }}:</span>{{ selectedBlock.dec|decimal2sexagesimal(true) }}º</span>
                    <span class="mr-5"><span class="details-label">{{ $t('Scheduled time') }}:</span>{{ selectedBlock.scheduled_time.slice(11) }}</span>
                    <span class="mr-5"><span class="details-label">{{ $t('Instrument') }}:</span>{{ selectedBlock.instrument }}</span>
                </div>
                <v-divider class="my-3" />
                <v-data-table class="lines-data-table"
                  :headers="selectedBlockLinesTableHeaders"
                  :items="selectedBlock.lines" item-key="prereduced_cube"
                  :hide-default-footer="selectedBlock.lines.length <= 10"
                >
                  <template #[`item.exposure_time`]="{ item }">
                    {{ item.exposure_time.toFixed(0) }}s
                  </template>
                  <template #[`item.status`]="{ item }">
                    <status-chip small :value="item.status" kind="observing-line" />
                  </template>
                  <template #[`item.measured_snr`]="{ item }">
                    {{ item.measured_snr?item.measured_snr.toFixed(2):'' }}
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-btn icon v-if="item.raw_cube_public_url"
                      :title="$t('Download raw cube')"
                      :href="item.raw_cube_public_url"
                    ><v-icon>mdi-download-box</v-icon></v-btn>
                    <v-btn icon v-if="item.image_public_url"
                      :title="$t('Download reduced image')"
                      :href="item.image_public_url"
                    ><v-icon>mdi-file-download</v-icon></v-btn>
                    <v-btn icon v-if="item.image_public_url"
                      :title="$t('Open in ginga viewer')"
                    ><v-icon>mdi-eye</v-icon></v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <logbook :date="form.date" />
      </v-col>
    </v-row>
  </v-card>
</template>
  
<script>
import Vue from 'vue'
import StatusChip from '@/components/utils/StatusChip.vue';
import TimelineTelescopeGroupLabel from '@/components/utils/TimelineTelescopeGroupLabel.vue';
import Logbook from '@/components/monitoring/Logbook.vue';
import { Timeline } from "@vue2vis/timeline";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import constants from "@/helpers/constants";
import * as moment from "moment";

const STATUS_COLOR = constants.OBSERVING_BLOCK_STATUS_CHOICES.reduce(
  (obj, item) => Object.assign(obj, { [item.value]: item.color }), {}
)

const currentNight = () => {
  let now = new Date();
  if (now.toISOString().slice(11,13) < 12) {
    now.setDate(now.getDate() - 1);
  }
  return now.toISOString().slice(0, 10)
}

export default {
  name: "OperationalMonitoring",
  components: { Timeline, StatusChip , Logbook },
  props: ["active", ],
  data: function() {
    let now = new Date();
    let oneHourBefore = new Date(now.getTime());
    oneHourBefore.setHours(oneHourBefore.getHours() - 1);
    let oneHourAfter = new Date(now.getTime());
    oneHourAfter.setHours(oneHourAfter.getHours() + 1);
    return {
      loading: false,
      blockLoading: false,
      lastFetchTime: null,
      telescopes: [],
      timeFrom: oneHourBefore,
      timeTo: oneHourAfter,
      observingBlocks: [],
      dateMenu: false,
      form: {
        date: currentNight(),
      },
      selectedBlock: null,
      debug: null,
      automaticFetch:null,
      timelineOptions: {
        start: oneHourBefore,
        end: oneHourAfter,
        min: oneHourBefore,
        max: oneHourAfter,
        stack: false,
        moment: function(date) {
          return moment(date).utc();
        },
        groupTemplate: function(group) {
          if (group) {
            var instance = new Vue({
              ...TimelineTelescopeGroupLabel,
              propsData: {group: group}
            });
            instance.$mount();
            return instance.$el;
          } else return ''
        },
      }
    }
  },
  computed: {
    formValid() {
      return !!this.form.date
    },
    timelineItems() {
      return this.observingBlocks.map(obj => ({
        id: obj.id,
        group: obj.telescope_id,
        subgroup: 0,
        title: obj.target,
        start: obj.scheduled_time,
        end: obj.scheduled_end_time,
        run: obj.run_id,
        status: obj.status,
        className: STATUS_COLOR[obj.status],
        type: 'range',
      }));
    },
    selectedBlockLinesTableHeaders() {
      return [
        { text: this.$t('OBL#'), value: 'id', align: 'center', sortable: false, divider: true, width: 80 },
        { text: this.$t('Filter'), value: 'filter', align: 'center', },
        { text: this.$t('Exposure time'), value: 'exposure_time', align: 'center', },
        { text: this.$t('Expected SNR'), value: 'expected_snr', align: 'center', },
        { text: this.$t('Measured SNR'), value: 'measured_snr', align: 'center', },
        { text: this.$t('Status'), value: 'status', align: 'center', },
        { text: this.$t('Actions'), value: 'actions', align: 'center', },
      ]
    },
  },
  mounted() {
  },
  methods: {
    fetch(updateOptions=false) {
      this.loading = true;
      var thisFetchTime = this.lastFetchTime = Date.now()
      return this.$apiRequest('get', "schedule/", {
        params: this.form,
      }).then(res => {
        if (this.lastFetchTime <= thisFetchTime) {
          this.timeFrom = res.data.start_time;
          this.timeTo = res.data.end_time;
          if (updateOptions) {
            this.timelineOptions.min = moment(this.timeFrom).add(-5, 'm').toDate().toISOString();
            this.timelineOptions.max = moment(this.timeTo).add(+5, 'm').toDate().toISOString();
            if (this.form.date == currentNight()) {
              this.timelineOptions.start = moment().add(-5, 'm').toDate().toISOString();
              this.timelineOptions.end = moment().add(+5, 'm').toDate().toISOString();
            } else {
              this.timelineOptions.start = moment(this.timeTo).add(-5, 'm').toDate().toISOString();
              this.timelineOptions.end = moment(this.timeTo).add(+5, 'm').toDate().toISOString();
            }
          }
          this.observingBlocks = res.data.blocks;
          this.telescopes = res.data.telescopes;
        }
      }).finally(() => (this.loading = false))
    },
    handleTimelineClick(e) {
      if (e.isCluster) {
        this.$refs.timeline.setSelection([]);
      }
    },
    handleTimelineSelect(e) {
      if (!e.items) {
        return
      }
      if ((e.items.length == 0) || !Number.isInteger(e.items[0])) {
        this.selectedBlock = null;
        return
      }
      this.loadingBlock = true;
      this.selectedBlock = null;
      this.$apiRequest('get', `observing-blocks/${e.items[0]}/`).then(res => {
        this.selectedBlock = res.data;
      }).finally(() => (this.loading = false))
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        if (this.formValid) {
          this.selectedBlock = null;
          this.fetch(true)
        }
      },
    },
    active: {
      immediate: true,
      handler(newValue) {
        if(newValue == true) {
          this.automaticFetch = setInterval(() => this.fetch(false), 60000);
        } else {
          clearInterval(this.automaticFetch);
        }
      },
    },
  }
}
</script>

<style scoped>
.timeline {
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.5);
}
.timeline >>> .vis-item {
  height: 100px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.8;
}
.timeline >>> .vis-item.vis-selected {
  border-color: rgb(251, 255, 0) !important;
  border-width: thin !important;
}
.timeline >>> .vis-timeline {
  stroke: none;
  border-radius: 5px;
}
.timeline >>> .vis-panel.vis-bottom {
  background-color: #dfdfdf;
}
.timeline >>> .vis-panel.vis-left {
  background-color: #dfdfdf;
}
.timeline >>> .vis-time-axis.vis-text {
  color: rgba(0, 0, 0, 0.5);
}
.lines-data-table >>> .v-data-footer__select > .v-input {
  display: none;
}
.details-label {
  font-weight: bold;
  margin-right: 10px;
}
</style>
