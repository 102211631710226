<template>
    <v-card class="mt-3" flat>
      <div>
        <v-select v-model="selectedHeaders" :items="headers" :label="$t('Visible Columns')" multiple outlined return-object>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 4">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 4" class="grey--text caption">(+{{ selectedHeaders.length - 4 }} others)</span>
          </template>
        </v-select>
      </div>
      <!-- Tabla -->
      <v-data-table
        :headers="showHeaders"
        :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
        :items="filteredItemsTable"
        :search="filters.search"
        class="striped"
      >
        <!-- Filtro -->
        <template #top>
          <v-row no-gutters>
              <v-col cols="4">
                  <v-text-field v-model="filters.search"
                      :label="$t('Search')"
                      :placeholder="$t('Search by from/to org., type, validity or amount')"
                      append-icon="mdi-magnify"
                      filled dense hide-details clearable />
              </v-col>
              <v-col cols="3">
                  <v-datetime-picker date-format="yyyy-MM-dd" time-format="HH:mm" :label="$t('Since') + ' ' + $t('Date')" v-model="filters.from"
                          :text-field-props="{ hideDetails: true, filled: true, dense: true, appendIcon: 'mdi-calendar' }"
                          :date-picker-props="{ noTitle: true, }"
                          :time-picker-props="{ noTitle: true, format: '24hr' }"
                      >
                      <template #dateIcon><v-icon>mdi-calendar</v-icon></template>
                      <template #timeIcon><v-icon>mdi-clock</v-icon></template>
                      <template #actions="{ parent }">
                        <v-btn text color="secondary" @click.native="parent.clearHandler">{{ $t('Clear') }}</v-btn>
                        <v-btn text color="primary" @click="parent.okHandler">{{ $t('Ok') }}</v-btn>
                      </template>
                  </v-datetime-picker>
              </v-col>    
              <v-col cols="3">
                  <v-datetime-picker date-format="yyyy-MM-dd" time-format="HH:mm" :label="$t('Until') + ' ' + $t('Date')" v-model="filters.to"
                          :text-field-props="{ hideDetails: true,  filled: true, dense: true, appendIcon: 'mdi-calendar' }"
                          :date-picker-props="{ noTitle: true, }"
                          :time-picker-props="{ noTitle: true, format: '24hr' }"
                      >
                      <template #dateIcon><v-icon>mdi-calendar</v-icon></template>
                      <template #timeIcon><v-icon>mdi-clock</v-icon></template>
                      <template #actions="{ parent }">
                      <v-btn text color="secondary" @click.native="parent.clearHandler">{{ $t('Clear') }}</v-btn>
                      <v-btn text color="primary" @click="parent.okHandler">{{ $t('Ok') }}</v-btn>
                      </template>
                  </v-datetime-picker>
              </v-col>     
              <v-col cols="2">
                  <v-select v-model="filters.type" :items="itemType" :label="$t('Type')" filled dense hide-details clearable>
                      <template #selection="{ item }">
                          {{ item }}
                      </template>
                  </v-select>
              </v-col>    
          </v-row>
        </template>
        <!-- Personalizar columna tabla -->
        <!-- Type -->
        <template v-slot:header.type="{ header }">
          <div class="text-center text-no-wrap">{{header.text}}</div>
        </template>
        <template v-slot:item.type="{ item }">
          <div class="text-center text-no-wrap">{{getValueType(item.type)}}</div>
        </template>
        <!-- Validity -->
        <template v-slot:header.validity="{ header }">
          <div class="text-center text-no-wrap">{{header.text}}</div>
        </template>
        <template v-slot:item.validity="{ item }">
          <div class="text-center text-no-wrap">{{getYear(item.validity)}}</div>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>


export default {
  name: "AuditTableTransaction",
  components: {  },
  props: {
    items: {
      type: Array,
    },
  },
  data: function() {
    return {
      headers: [
        { value: 'date', text: this.$i18n.t('Date'), align: 'center', sortable: true},
        { value: 'from_organization', text: this.$i18n.t('From organization'), align: 'center', sortable: true},
        { value: 'to_organization', text: this.$i18n.t('To organization'), align: 'center', sortable: true},
        { value: 'type', text: this.$i18n.t('Type'), align: 'center', sortable: true},
        { value: 'amount', text: this.$i18n.t('Amount'), align: 'center', sortable: true},
        { value: 'observations', text: this.$i18n.t('Observations'), align: 'center', sortable: true},
        { value: 'validity', text: this.$i18n.t('Validity'), align: 'center', sortable: true},
        
      ],
      selectedHeaders: [],
      loading: false,
      filters: {
        from: null,
        to: null,
        search: null,
        type: null,
      },
      listType: [],
      itemType: ["Adquisition", "Consumption", "Endorsement", "Elapsed", "Regularization"],
    }
  },
  computed: {
    showHeaders () {
      return this.headers.filter(heder => this.selectedHeaders.includes(heder));
    },
    filteredItemsTable(){
      return this.getFilteredItems;
    },
    getFilteredItems(){
      // Ningun filtro aplicado
      if (this.filters.search===null && this.filters.type===null && this.filters.from===null && this.filters.to===null) {
        return this.items;
      }
      var filterType = !this.filters.type? null : this.filters.type.trim().toLowerCase();
      var filterDateFrom = !this.filters.from? null : new Date(this.filters.from);
      var filterDateTo = !this.filters.to? null : new Date(this.filters.to);
      var filterSearch = !this.filters.search? null : this.filters.search.trim().toLowerCase();
      // Si fecha de inicio y final estan seteadas
      if(this.filters.from!==null && this.filters.to!==null){
        return this.items.filter(item => {
          const typeMatch = !filterSearch || this.getValueType(item.type).trim().toLowerCase().includes(filterSearch);
          const validityMatch = !filterSearch || "".concat(item.validity).trim().toLowerCase().includes(filterSearch);
          const amountMatch = !filterSearch || "".concat(item.amount).trim().toLowerCase().includes(filterSearch);
          const from = !filterSearch || "".concat(item.from_organization).trim().toLowerCase().includes(filterSearch);
          const to = !filterSearch || "".concat(item.to_organization).trim().toLowerCase().includes(filterSearch);
          return (typeMatch || amountMatch || from || to || validityMatch) &&
              (!filterType || this.getValueType(item.type).trim().toLowerCase().includes(filterType)) &&
              ( (!filterDateFrom && !filterDateTo) || new Date(item.date)>=filterDateFrom && new Date(item.date)<=filterDateTo);
        });
      }else{
        return this.items.filter(item => {
          const typeMatch = !filterSearch || item.type.trim().toLowerCase().includes(filterSearch);
          const validityMatch = !filterSearch || "".concat(item.validity).trim().toLowerCase().includes(filterSearch);
          const amountMatch = !filterSearch || "".concat(item.amount).trim().toLowerCase().includes(filterSearch);
          const from = !filterSearch || "".concat(item.from_organization).trim().toLowerCase().includes(filterSearch);
          const to = !filterSearch || "".concat(item.to_organization).trim().toLowerCase().includes(filterSearch);
          return (typeMatch || amountMatch || from || to || validityMatch) &&
              (!filterType || item.type.trim().toLowerCase().includes(filterType)) &&
              (!filterDateFrom || new Date(item.date)>=filterDateFrom) &&
              (!filterDateTo || new Date(item.date)<=filterDateTo); 
        });
      }
    },
  },
  created(){
    this.fetchTypeTransaction();
    this.selectedHeaders = this.headers;
  },
  mounted() {
    // console.log("[abe] - registros Tabla Transaction: ",this.items);
  },
  methods: {
    formatDate(date) {
      if (date ) {
        const dateObject = new Date(date);
        const mes = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        const dia = dateObject.getDate().toString().padStart(2, "0");

        const horas = dateObject.getHours().toString().padStart(2, "0");
        const minutos = dateObject.getMinutes().toString().padStart(2, "0");
        const segundos = dateObject.getSeconds().toString().padStart(2, "0");

        return dateObject.getFullYear()+"-"+mes+"-"+dia+" "+horas+":"+minutos+":"+segundos;
      } else {
        return null;
      }
    },
    capitalizeFirstLetter(word) {
      if(typeof word!=='undefined'){
        return word.charAt(0).toUpperCase() + word.slice(1);
      }else{
        return word;
      }
    },
    getYear(date) {
      const dateObject = new Date(date);
      return dateObject.getFullYear();
    },
    fetchTypeTransaction(){
      return this.$apiRequest('get', 'transactions/types/').then((res) => {
        if(typeof res != 'undefined'){
          res.data.map(item => {
            var aux = new Object();
            aux.key = item[0];
            aux.value = item[1];
            this.listType.push(aux)
          })
          this.listType = this.listType.sort(function(a, b) {
            return a.value.localeCompare(b.value);
          });
          this.itemsStatus = this.listType.map(item => item.value);
        }
      })
    },
    getValueType(key){
      return (!!key && typeof key != 'undefined' )? this.listType.filter(item => item.key===key).map(item => item.value).reduce((accum, currVal) => accum+ currVal, "") : "";
    },
    
    
  },
  watch: {
    
  },
  
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
</style>
