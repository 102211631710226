<template>
  <v-card :loading="loading" class="ma-3" flat>
    <v-card-title class="d-flex justify-space-between">
      <div>
        <v-tooltip right>
          <template #activator="{ on }">
            <v-btn color="secondary" icon @click="fetch()" v-on="on" class="mr-5"><v-icon>mdi-refresh</v-icon></v-btn>
          </template>
          <span>{{ $t("Refresh") }}</span>
        </v-tooltip>
        <span>{{ $t("Audit") }}</span>
      </div>
    </v-card-title>
    <v-divider class="mb-3" />
    <v-tabs v-model="tab" vertical>
      <v-tab>{{ $t('My User') }}</v-tab>
      <v-tab v-if="this.isOrganizationAdmin">{{ $t('Organization') }}</v-tab>
      <v-tab v-if="this.isAdministratorUser">{{ $t('Administration') }}</v-tab>
      <v-tabs-items v-model="tab">
        <!-- My User -->
        <v-tab-item>
          <v-expansion-panels v-model="panels.user.proposal" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header color="primary lighten-5"> 
                <v-card class="my-3" color="primary lighten-5" elevation="0">
                    <v-card-title class="text-overline pb-0">{{ $t('Proposal Audit') }}</v-card-title>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <AuditTable 
                      :items="this.dto.user.merge"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <!-- Organization -->
        <v-tab-item v-if="this.isOrganizationAdmin">
          <v-expansion-panels v-model="panels.entity.proposal" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header color="primary lighten-5"> 
                <v-card class="my-3" color="primary lighten-5" elevation="0">
                    <v-card-title class="text-overline pb-0">{{ $t('Proposal Audit') }}</v-card-title>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <AuditTable 
                      :items="this.dto.entity.merge"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels v-model="panels.entity.transaction" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header color="primary lighten-5"> 
                <v-card class="my-3" color="primary lighten-5" elevation="0">
                    <v-card-title class="text-overline pb-0">{{ $t('Transactions Audit') }}</v-card-title>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <AuditTableTransaction 
                      :items="this.dto.entity.transaction"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <!-- Administrator user -->
        <v-tab-item v-if="this.isAdministratorUser">
          <v-expansion-panels v-model="panels.admin.transaction" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header color="primary lighten-5"> 
                <v-card class="my-3" color="primary lighten-5" elevation="0">
                    <v-card-title class="text-overline pb-0">{{ $t('Transactions Audit') }}</v-card-title>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <AuditTableTransaction 
                      :items="this.dto.admin.transaction"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>
<script>

import AuditTable from '@/components/audit/AuditTable.vue'
import AuditTableTransaction from '@/components/audit/AuditTableTransaction.vue'

export default {
    name: 'Audit',
    components: { 
      AuditTable, AuditTableTransaction
    }, 
    watch: {
      
    },
    data: function() {
        return {
          tab: 0,
          loading: false,
          dto: {
            user: {
              assignment: [],
              locked: [],
              consumtion: [],
              merge: []
            },
            entity: {
              assignment: [],
              locked: [],
              consumtion: [],
              transaction: [],
              merge: []
            },
            admin: {
              transaction: [],
            },
          },
          panels: {
            user:{
              proposal:[]
            },
            entity:{
              proposal:[],
              transaction: [],
            },
            admin:{
              transaction: [],
            },
          },
          isOrgAdmin: null
        }
    },
    created(){
      this.expanPanels();
      this.fetchIsOrgAdmin();
    },
    mounted() {
      
    },
    computed: {
      isOrganizationAdmin(){
        return this.isOrgAdmin;
      },
      isAdministratorUser(){
        return this.$store.getters['auth/isAdmin'];
      },
    },
    methods: {
      async fetch() {
        this.loading = true;
        let reqs = [];

        reqs.push(this.fetchDtoAstronomerAssignment());
        reqs.push(this.fetchDtoAstronomerLocked());
        reqs.push(this.fetchDtoAstronomerConsumtion());

        if(this.isOrganizationAdmin){
          reqs.push(this.fetchDtoEntityAssignment());
          reqs.push(this.fetchDtoEntityLocked());
          reqs.push(this.fetchDtoEntityConsumtion());
          reqs.push(this.fetchDtoEntityTransaction());
        }

        if(this.isAdministratorUser){
          reqs.push(this.fetchDtoAdminTransaction());
        }


        Promise.all(reqs).then(() => {

        }).finally(() => {
          this.merge();
          this.loading = false;
        }).catch(error => {
            this.$store.dispatch('alert/alert', {
              type: "error",
              message: this.$t("Operation error: ",error)
            });
        });
        return;
      },
      fetchDtoAstronomerAssignment(){
        return this.$apiRequest('get', 'proposals-assignment/dto/user/').then((res) => {
          this.dto.user.assignment = (typeof res != 'undefined')? res.data : [];
        })
      },
      fetchDtoAstronomerLocked(){
        return this.$apiRequest('get', 'observing-run-locks/dto/user/').then((res) => {
          this.dto.user.locked = (typeof res != 'undefined')? res.data.results : [];
        })
      },
      fetchDtoAstronomerConsumtion(){
        return this.$apiRequest('get', 'transactions/consumption/dto/user/').then((res) => {
          this.dto.user.consumtion = (typeof res != 'undefined')? res.data : [];
        })
      },
      fetchDtoEntityAssignment(){
        return this.$apiRequest('get', 'proposals-assignment/dto/entity/').then((res) => {
          this.dto.entity.assignment = (typeof res != 'undefined')? res.data : [];
        })
      },
      fetchDtoEntityLocked(){
        return this.$apiRequest('get', 'observing-run-locks/dto/entity/').then((res) => {
          this.dto.entity.locked = (typeof res != 'undefined')? res.data.results : [];
        })
      },
      fetchDtoEntityConsumtion(){
        return this.$apiRequest('get', 'transactions/consumption/dto/entity/').then((res) => {
          this.dto.entity.consumtion = (typeof res != 'undefined')? res.data : [];
        })
      },
      fetchDtoEntityTransaction(){
        return this.$apiRequest('get', 'transactions/dto/entity/all/').then((res) => {
          this.dto.entity.transaction = (typeof res != 'undefined')? res.data : [];
        })
      },
      fetchDtoAdminTransaction(){
        return this.$apiRequest('get', 'transactions/list/').then((res) => {
          this.dto.admin.transaction = (typeof res != 'undefined')? res.data : [];
        })
      },
      fetchIsOrgAdmin() {
        return this.$apiRequest('get', 'organizations/admin/').then((res) => {
          this.isOrgAdmin = (typeof res !== 'undefined' && typeof res.data ==='object');
        }).then(() => {
          this.fetch();
        });
      }, 
      merge(){
        // Usuario
        this.dto.user.merge = [];
        this.dto.user.assignment.map(item =>{
          this.dto.user.merge.push(item)
        });
        this.dto.user.consumtion.map(item =>{
          this.dto.user.merge.push(item)
        });
        this.dto.user.locked.map(item =>{
          this.dto.user.merge.push(item)
        });
        this.dto.user.merge.sort((a, b) => new Date(b.date) - new Date(a.date)); //Orden desc

        // Entidad
        if(this.isOrganizationAdmin){
          this.dto.entity.merge = [];
          this.dto.entity.assignment.map(item =>{
            this.dto.entity.merge.push(item)
          });
          this.dto.entity.consumtion.map(item =>{
            this.dto.entity.merge.push(item)
          });
          this.dto.entity.locked.map(item =>{
            this.dto.entity.merge.push(item)
          });
          this.dto.entity.merge.sort((a, b) => new Date(b.date) - new Date(a.date)); //Orden desc
        }
      },
      expanPanels(){
        this.panels.user.proposal = [0];
        this.panels.admin.transaction = [0];
      },
    }
}
</script>

<style scoped>
.bg-transparente{
  background-color: red;
}
</style>