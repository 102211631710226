
<template>
  <div class="d-flex align-center" style="width: 80px; height: 100px;">
      <div class="text-overline telescope-name">{{ group.name.split("-")[0] }}</div>
      <div class="d-flex flex-wrap justify-center align-center">
        <v-chip dark x-small label
          v-for="(value, status) in group.counts" :key="status"
          :color="STATUS_COLOR[status]" v-show="value > 0">{{ value }}</v-chip>
      </div>
  </div>
</template>

<script>
import constants from "@/helpers/constants";

const STATUS_COLOR = constants.OBSERVING_BLOCK_STATUS_CHOICES.reduce(
  (obj, item) => Object.assign(obj, { [item.value]: item.color }), {}
)

export default {
  name: "TimelineTelescopeGroupLabel",
  props: ["group", ],
  data() {
    return {
        STATUS_COLOR: STATUS_COLOR,
    }
  },
  computed: {
  },
}
</script>

<style scoped>
.telescope-name {
  font-weight: bold;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}
</style>