<template>
  <v-app class="grey lighten-4">
    <alert />
    <navbar />
    <v-main>
      <transition name="fade" mode="out-in" :duration="1000">
          <v-container class="justify-center" fill-height fill-width v-if="loading">
              <v-img alt="Rictel TTT" class="shrink" contain src="@/assets/img/logo.png"
                  transition="scale-transition" width="500"/>
          </v-container>
          <router-view v-else />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/base/Navbar";
import Alert from "@/components/base/Alert";
import { version } from "@/config.js";

export default {
  name: "App",
  components: { Navbar, Alert },
  data() {
    return {
      loading: true,
      version: version,
    };
  },
  created () {
    this.$store.dispatch("appSettings/restoreSettings");
    this.$root.$i18n.locale = this.$store.state.appSettings.locale;
    this.$store.dispatch('auth/tryRestoreSession').then(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.footer {
  clear: both;
  width: 100%;
  text-align: center;
}

.footer a {
  color: rgb(125, 125, 125);
  text-decoration: none;
  z-index: 1;
}

.footer a:hover {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.fade-enter-active {
  transition: opacity .5s ease;
}
.fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
