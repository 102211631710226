<template>
  <div>
      <v-row>
        <template v-if="!!selectedTelescope">
        <v-col cols="1" class="d-flex align-center">
          <v-btn class="mx-1" icon @click="fetch"><v-icon>mdi-refresh</v-icon></v-btn>
        </v-col>
        <v-col cols="8" class="d-flex align-center justify-space-around">
          <div><span class="time-label">{{ $t("Observatory") }}:</span><span>{{ selectedTelescope.time.Observatory }}</span></div>
          <div><span class="time-label">{{ $t("UT") }}:</span><span>{{ selectedTelescope.time.UT }}</span></div>
          <div><span class="time-label">{{ $t("LST") }}:</span><span>{{ selectedTelescope.time.LST|decimal2sexagesimal }}</span></div>
        </v-col>
      </template>
        <v-col cols="3">
          <v-select
            v-model="telescope"
            :items="telescopes"
            :label="$t('Telescope')"
            item-text="name" return-object
            append-icon="mdi-telescope"
            filled
            hide-details
          />
        </v-col>
      </v-row>
      <div v-if="!!selectedTelescope">
      <v-row>
        <v-col cols="3">
          <v-simple-table class="striped mt-3">
            <thead>
              <tr>
                <th class="text-center text-overline" colspan="3"> {{ $t('Telescope status') }}</th>
              </tr>
            </thead>
            <template v-if="!!selectedTelescope.status.telescopeStatus">
            <tbody>
              <tr>
                <td class="text-left table-label"> {{ $t("Status") }}</td>
                <td class="text-right"><status-chip kind="telescope" :value="selectedTelescope.status.telescopeStatus.status  ? selectedTelescope.status.telescopeStatus.status: null" /></td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Mirror cover") }}</td>
                <td class="text-right"> <status-chip kind="open-close" :value="selectedTelescope.status.telescopeStatus.mirror_cover ? selectedTelescope.status.telescopeStatus.mirror_cover : null" /></td>
              </tr>
            </tbody>
          </template>
          </v-simple-table>
          <v-simple-table class="striped mt-3">
            <thead>
              <tr>
                <th class="text-center text-overline" colspan="3"> {{ $t('Dome status') }}</th>
              </tr>
            </thead>
            <template v-if="!!selectedTelescope.status.dome">
            <tbody>
              <tr>
                <td class="text-left table-label"> {{ $t("West-side") }}</td>
                <td class="text-right"> <status-chip kind="open-close" :value="selectedTelescope.status.dome.domeWestClosed ? selectedTelescope.status.dome.domeWestClosed : null" /></td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("East-side") }}</td>
                <td class="text-right"> <status-chip kind="open-close" :value="selectedTelescope.status.dome.domeEastClosed ? selectedTelescope.status.dome.domeEastClosed : null" /></td>
              </tr>
            </tbody>
          </template>
          </v-simple-table>
          <v-simple-table class="striped mt-3">
            <thead>
              <tr>
                <th class="text-center text-overline" colspan="3"> {{ $t('Camera status') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left table-label"> {{ $t("Camera ID") }}</td>
                <td class="text-right" v-for="camera in selectedTelescope.status.cameras" :key="camera.id"> {{ camera.id }}</td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Status") }}</td>
                <td class="text-right" v-for="camera in selectedTelescope.status.cameras" :key="camera.id"> <status-chip kind="operating-inactive" :value="camera.status" /></td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Focus") }}</td>
                <td class="text-right" v-for="camera in selectedTelescope.status.cameras" :key="camera.id"> {{ camera.focus }} μ</td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Task") }}</td>
                <td class="text-right" v-for="camera in selectedTelescope.status.cameras" :key="camera.id"> {{ camera.task }}</td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("FIlter") }}</td>
                <td class="text-right" v-for="camera in selectedTelescope.status.cameras" :key="camera.id"> {{ camera.filter }}</td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Temperature") }}</td>
                <td class="text-right" v-for="camera in selectedTelescope.status.cameras" :key="camera.id"> {{ camera.temperature }} ºC</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12" class="d-flex justify-space-around align-center">
              <telescope-representation :az="selectedTelescope.status.telescope_pos.az" :zd="90-selectedTelescope.status.telescope_pos.alt" :dome-west-closed="selectedTelescope.status.dome.domeWestClosed=='closed'" :dome-east-closed="selectedTelescope.status.dome.domeEastClosed=='closed'" />
              <mirror-representation :zd="90-selectedTelescope.status.telescope_pos.alt" :mirror-cover="selectedTelescope.status.mirror_cover=='closed'" />
              <sky-map :ra="selectedTelescope.status.telescope_pos.ra/24*360" :dec="selectedTelescope.status.telescope_pos.dec"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-simple-table class="striped mt-3">
                <thead>
                  <tr>
                    <th class="text-center text-overline" colspan="6"> {{ $t('Telescope position') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center table-label"> {{ $t("RA") }}</td>
                    <td class="text-center table-label"> {{ $t("DEC") }}</td>
                    <td class="text-center table-label"> {{ $t("AZ") }}</td>
                    <td class="text-center table-label"> {{ $t("ALT") }}</td>
                  </tr>
                  <tr>
                    <td class="text-center"> {{ selectedTelescope.status.telescope_pos.ra?$options.filters.decimal2sexagesimal(selectedTelescope.status.telescope_pos.ra):"N/A" }} </td>
                    <td class="text-center"> {{ selectedTelescope.status.telescope_pos.dec?$options.filters.decimal2sexagesimal(selectedTelescope.status.telescope_pos.dec,true):"N/A" }} </td>
                    <td class="text-center"> {{ (selectedTelescope.status.telescope_pos.az != null)?selectedTelescope.status.telescope_pos.az.toFixed(2):"N/A" }} </td>
                    <td class="text-center"> {{ (selectedTelescope.status.telescope_pos.alt != null)?selectedTelescope.status.telescope_pos.alt.toFixed(2):"N/A" }} </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-simple-table class="striped mt-3">
            <thead>
              <tr>
                <th class="text-center text-overline" colspan="3"> {{ $t('Weather status at')}} {{ telescope.name }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> </td>
                <td class="text-center table-label"> {{ $t("Value") }}</td>
                <td class="text-center table-label"> {{ $t("Last update") }}</td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Temperature") }}</td>
                <td class="text-right"> {{ meteo?meteo.temperature.value.toFixed(2):'N/A' }} ºC</td>
                <td class="text-right"> {{ meteo?moment(meteo.temperature._time).fromNow():'N/A' }} </td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Humidity") }}</td>
                <td class="text-right"> {{ meteo?meteo.humidity.value.toFixed(2):"N/A" }} %</td>
                <td class="text-right"> {{ meteo?moment(meteo.humidity._time).fromNow():"N/A" }} </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table class="striped mt -3">
            <thead>
              <tr>
                <th class="text-center text-overline" colspan="3"> {{ $t('Astronomical status') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left table-label"> {{ $t("Sun alt") }}</td>
                <td class="text-right"> {{ selectedTelescope.astronomical ? selectedTelescope.astronomical.sun_alt.toFixed(2) :"N/A"  }} </td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Moon alt") }}</td>
                <td class="text-right"> {{ selectedTelescope.astronomical ?  selectedTelescope.astronomical.moon_alt.toFixed(2) :"N/A"  }} </td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Moon phase") }}</td>
                <td class="text-right"> {{ selectedTelescope.astronomical? selectedTelescope.astronomical.moon_phase.toFixed(2) :"N/A"  }} </td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Moon distance") }}</td>
                <td class="text-right"> {{ selectedTelescope.astronomical ?selectedTelescope.astronomical.moon_distance.toFixed(2):"N/A" }} </td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Galactic distance") }}</td>
                <td class="text-right"> {{ selectedTelescope.astronomical ?selectedTelescope.astronomical.galactic_distance.toFixed(2):"N/A" }} </td>
              </tr>
              <tr>
                <td class="text-left table-label"> {{ $t("Ecliptic distance") }}</td>
                <td class="text-right"> {{ selectedTelescope.astronomical?selectedTelescope.astronomical.ecliptic_distance.toFixed(2):"N/A" }} </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex align-center justify-space-around" style="height: 400px" v-else>
      <v-progress-circular size="50" color="primary" indeterminate />
    </div>
  </div>
</template>
  
<script>
import StatusChip from '@/components/utils/StatusChip.vue';
import * as moment from "moment";
import TelescopeRepresentation from './TelescopeRepresentation.vue';
import MirrorRepresentation from './MirrorRepresentation.vue';
import SkyMap from '../utils/SkyMap.vue';

export default {
  name: "StatusOverview",
  components: { StatusChip, TelescopeRepresentation, MirrorRepresentation, SkyMap },
  props: ["active", ],
  data: function() {
    moment.locale("en")
    let now = new Date();
    let options = {
      timeZone: 'Atlantic/Canary',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    formatter = new Intl.DateTimeFormat([], options);
    return {
      loading: false,
      telescopes: [],
      telescope: null,
      selectedTelescope: null,
      meteo: null,
      time: {
        utc: now.toUTCString(),
        local: formatter.format(now),
      },
      moment: moment,
      automaticTelescopeFetch: null,
      automaticWeatherFetch: null,
    }
  },
  computed:{
  },
  mounted(){
    // this.fetch();
    this.loading = true;
    this.$apiRequest('get', 'telescopes/').then((res) => {
      this.loading = false;
      this.telescopes = res.data;
      if (this.telescopes.length > 0) {
        this.telescope = this.telescopes[0]
      }
    })
  },
  methods:{
    fetchTelescopeData(){
      this.loading = true;
      if (this.telescope) {
        this.$apiRequest('get', 'monitoring/telescope-status/', {
          params: {
            telescope: this.telescope.name,
          }
        }).then((res) => {
          this.loading = false;
          this.selectedTelescope = res.data;
        })
      }
    },
    fetchWeatherData(){
      this.loading = true;
      if (this.telescope) {
        this.$apiRequest('get', 'monitoring/telescope-weather-status/', {
          params: {
            telescope: this.telescope.name,
          }
        }, [500]).then((res) => {
          this.loading = false;
          this.meteo = res.data;
        }).catch(() => {
          this.$store.dispatch('alert/alert', {
            type: "warning",
            message: this.$t("Unable to connect with InfluxDB")
          });
          // this.meteo = null;
        })
      }
    },
    fetch(){
      this.fetchTelescopeData();
      this.fetchWeatherData();
    }
  },
  watch: {
    telescope() {
      this.selectedTelescope = null;
      this.fetch();
    },
    active: {
      immediate: true,
      handler(newValue) {
        if(newValue == true) {
          var counter= 0
          this.automaticTelescopeFetch = setInterval(() => {
            this.fetchTelescopeData();
            counter++;
            if (counter === 3) {
                counter = 0
                clearInterval(this.automaticTelescopeFetch);
                
            }
          }, 10000);
          this.automaticWeatherFetch = setInterval(() => {
            this.fetchWeatherData();
            counter++;
            if (counter === 3) {
                counter = 0
                clearInterval(this.automaticcWeatherFetch);
                
            }
          }, 60000);
        } else {
          clearInterval(this.automaticTelescopeFetch);
          clearInterval(this.automaticWeatherFetch);
        }
      },
    }
  }
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
.time-label {
  font-weight: bold;
  margin-right: 5px;
}
.table-label {
  font-weight: bold;
}
</style>
  
