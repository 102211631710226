<template>
  <ginga fitsPath="data/TTT2_QHY411-2_2023-05-18-21-37-09-628449_SA100-280.fits" :width="600" :height="600" />
</template>

<script>
import Ginga from '@/components/ginga/Ginga.vue'

export default {
    components: { Ginga },
    name: 'Dev',
    data: function() {
        return {
        }
    }
}
</script>

<style scoped>
</style>