
const currentNight = () => {
    let now = new Date();
    if (now.toISOString().slice(11,13) < 12) {
      now.setDate(now.getDate() - 1);
    }
    return now.toISOString().slice(0, 10)
}

const randomColor = (darken=true) => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  var factor = darken?8:16;
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * factor)];
  }
  return color;
}
const parseTime = (time) => {
  let days = 0;
  if (time) {
    const parts = time.split(' ');
    if (parts.length === 2) {
      [days, time] = parts;
    } else {
      time = parts[0];
    }
    const timeParts = time.split(':');
    if (timeParts.length === 3) {
      const [hours, minutes, seconds] = timeParts;
      const formattedHours = parseInt(days, 10)* 24 + parseInt(hours, 10);
      return `${formattedHours}:${parseInt(minutes, 10)}:${parseInt(seconds.split('.')[0], 10)}`;
    }
  }
  return "00:00:00";
}

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,      
  maximumFractionDigits: 2,
  useGrouping: false
});

export { currentNight, randomColor, parseTime ,formatter}
