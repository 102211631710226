
<template>
    <v-card class="mb-3 mr-1" color="primary lighten-5" elevation="0">
        <v-card-title class="text-overline pb-0">{{ $t('Cadence constraints') }}</v-card-title>
        <v-card-text>
            <p class="text-caption">{{ $t('With cadence constraints you can adjust the minimum/maximum time separation of your repetitions.') }}</p>
            <v-row>
                <v-col cols="2">
                    <v-checkbox v-model="active" :disabled="disabled" :label="$t('Set constraint')" />
                </v-col>
                <v-col cols="5">
                    <v-text-field outlined v-model="numberUnits" type="number" hide-details :disabled="disabled || !active" min="0"/>
                </v-col>
                <v-col cols="5">
                    <v-select outlined v-model="units" :items="unitOptions" :disabled="disabled || !active"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    name: "CadenceField",
    props: ["value", "disabled"],
    data() {
        return {
            active: false,
            numberUnits: 0,
            units: "hours",
        }
    },
    computed: {
        unitOptions() {
            return [
                { text: this.$t('Hours'), value: "hours" },
                { text: this.$t('Days'), value: "days" },
                { text: this.$t('Weeks'), value: "weeks" },
                { text: this.$t('Months'), value: "months" },
                { text: this.$t('Years'), value: "years" },
            ]
        },
        localValue() {
            switch (this.units) {
                case 'hours': return this.numberUnits;
                case 'days': return this.numberUnits * 24 - 8;
                case 'weeks': return this.numberUnits * 24 * 7 - 8;
                case 'months': return this.numberUnits * 24 * 30 - 8;
                case 'years': return this.numberUnits * 24 * 365 - 8;
                default: return 0;
            }
        },
    },
    methods: {
        isValid() {
            return !this.active || (this.numberUnits > 0);
        }
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                if (this.value == 0) {
                    this.active = false;
                    this.numberUnits = 0;
                    this.units = "hours";
                } else {
                    this.active = true;
                    let years = (this.value + 8) / (24*365);
                    if (Number.isInteger(years)) {
                        this.numberUnits = years;
                        this.units = "years";
                    } else {
                        let months = (this.value + 8) / (24*30);
                        if (Number.isInteger(months)) {
                            this.numberUnits = years;
                            this.units = "months";
                        } else {
                            let weeks = (this.value + 8) / (24*7);
                            if (Number.isInteger(weeks)) {
                                this.numberUnits = weeks;
                                this.units = "weeks";
                            } else {
                                let days = (this.value + 8) / 24;
                                if (Number.isInteger(days)) {
                                    this.numberUnits = days;
                                    this.units = "days";
                                } else {
                                    this.numberUnits = this.value;
                                    this.units = "hours";
                                }
                            }
                        }
                    }
                }
            },
        },
        localValue() {
            if (this.isValid()) {
                this.$emit('input', this.localValue)
            }
        },
    }
}
</script>

<style scoped>
</style>