<template>
    <v-card class="mt-3" flat>
        <v-data-table :loading="loading" :items="items" class="striped" :headers="headers"
          :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
          :options.sync="options" :server-items-length="totalItems" :items-per-page="itemsPerPage">
            <template #top>
                <v-row no-gutters>
                    <v-col cols="4">
                        <v-text-field v-model="filters.search"
                            :label="$t('Search')"
                            :placeholder="$t('Search by ID, filter name, or target')"
                            append-icon="mdi-magnify"
                            filled dense hide-details clearable />
                    </v-col>    
                    <v-col cols="3">
                        <v-datetime-picker date-format="yyyy-MM-dd" time-format="HH:mm" :label="$t('Since')" v-model="filters.from"
                                :text-field-props="{ hideDetails: true, filled: true, dense: true, appendIcon: 'mdi-calendar' }"
                                :date-picker-props="{ noTitle: true, }"
                                :time-picker-props="{ noTitle: true, format: '24hr' }"
                            >
                            <template #dateIcon><v-icon>mdi-calendar</v-icon></template>
                            <template #timeIcon><v-icon>mdi-clock</v-icon></template>
                            <template #actions="{ parent }">
                              <v-btn text color="secondary" @click.native="parent.clearHandler">{{ $t('Clear') }}</v-btn>
                              <v-btn text color="primary" @click="parent.okHandler">{{ $t('Ok') }}</v-btn>
                            </template>
                        </v-datetime-picker>
                    </v-col>    
                    <v-col cols="3">
                        <v-datetime-picker date-format="yyyy-MM-dd" time-format="HH:mm" :label="$t('Until')" v-model="filters.to"
                                :text-field-props="{ hideDetails: true,  filled: true, dense: true, appendIcon: 'mdi-calendar' }"
                                :date-picker-props="{ noTitle: true, }"
                                :time-picker-props="{ noTitle: true, format: '24hr' }"
                            >
                            <template #dateIcon><v-icon>mdi-calendar</v-icon></template>
                            <template #timeIcon><v-icon>mdi-clock</v-icon></template>
                            <template #actions="{ parent }">
                            <v-btn text color="secondary" @click.native="parent.clearHandler">{{ $t('Clear') }}</v-btn>
                            <v-btn text color="primary" @click="parent.okHandler">{{ $t('Ok') }}</v-btn>
                            </template>
                        </v-datetime-picker>
                    </v-col>    
                    <v-col cols="2">
                        <v-select v-model="filters.status" :items="statusChoices" :label="$t('Status')" filled dense hide-details clearable>
                            <template #selection="{ item }">
                                <v-chip small dark :color="item.color">{{ item.text }}</v-chip>
                            </template>
                        </v-select>
                    </v-col>    
                </v-row>
            </template>
            <template #[`item.status`]="{ item }">
                <status-chip small :value="item.status" kind="observing-line" />
            </template>
            <template #[`item.image_public_url`]="{ item }">
                <div class="d-flex justify-center align-center" v-if="item.image_public_url">
                    <v-btn :href="item.image_public_url" icon><v-icon>mdi-download</v-icon></v-btn>
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import StatusChip from '@/components/utils/StatusChip.vue';
import constants from '@/helpers/constants.js';

export default {
  name: "ProposalImages",
  components: { StatusChip },
  props: ["id",],
  data: function() {
    return {
      items: [],
      headers: [
        { value: 'id', text: '#', align: 'center', sortable: false, divider: true, width: 80, },
        { value: 'observing_run', text: this.$i18n.t('Observing run'), align: 'center', sortable: true },
        { value: 'target_name', text: this.$i18n.t('Target'), align: 'center', sortable: true },
        { value: 'observation_time', text: this.$i18n.t('Date of observation'), align: 'center', sortable: true },
        { value: 'filter', text: this.$i18n.t('Filter'), align: 'center', sortable: true },
        { value: 'status', text: this.$i18n.t('Status'), align: 'center', sortable: true },
        { value: 'image_public_url', text: this.$i18n.t('Download'), align: 'center', sortable: false },
      ],
      statusChoices: constants.OBSERVING_LINE_STATUS_CHOICES,
      loading: false,
      totalItems: 0,
      itemsPerPage: 0,
      lastFetchTime: null,
      selectedItem: null,
      filters: {
        search: null,
        from: null,
        to: null,
        status: '',
      },
      options: {},
    }
  },
  computed: {
    filterset() {
      let orderings = [];
      if (this.options.sortBy) {
        for (var i=0;i<this.options.sortBy.length;i++) {
          orderings.push((this.options.sortDesc[i]?'':'-') + this.options.sortBy[i]);
        }
      }
      return {
        search: this.filters.search,
        status: this.filters.status,
        from: this.formatDate(this.filters.from),
        to: this.formatDate(this.filters.to),
        ordering: orderings.join(","),
        page: this.options.page,
        page_size: this.options.itemsPerPage,
      }
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      var thisFetchTime = this.lastFetchTime = Date.now()
      this.$apiRequest('get', `proposals/${this.id}/images/`, {
        params: this.filterset,
      }).then(res => {
        if (this.lastFetchTime <= thisFetchTime) {
          this.items = res.data.results;
          this.totalItems = res.data.count;
          this.itemsPerPage = res.data.results.length;
        }
      }).finally(() => (this.loading = false))
    },
    formatDate(date) {
      // Datetime picker object that we use model's a javascript Date which is locally time aware
      // We want datetimes to be UTC so we need to to this trick to remove TZ info
      if (date) {
        let utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
        utcDate = new Date(utcDate);
        return utcDate.toISOString();
      } else {
        return null;
      }
    }
  },
  watch: {
    filterset: {
      handler (newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          this.$nextTick(this.fetch);
        }
      },
    },
  },
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
</style>
