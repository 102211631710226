
<template>
    <v-text-field v-model="localValue" v-bind="$attrs" :rules="rules"
        :placeholder="format" @keypress="isValidKey($event)"
        />
</template>

<script>
const zeroPad = (num, places) => String(num).padStart(places, '0')
export default {
    name: "SexagesimalField",
    props: {
        value: Number,
        hours: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            localValue: null,
            format: `${this.hours?'hh':'+/-dd'}:MM:SS`,
            rules: [
                value => !!value || this.$i18n.t('Required'),
                value => (value && this.sexagesimalIsValid(value)) || this.$i18n.t('Format must be {format}', { format: this.format }),
            ],
        }
    },
    methods: {
        isValidKey(e) {
            let regexPattern = this.hours?/[0-9.:]/i:/[0-9.+-:]/i
            if (regexPattern.test(e.key)) {
                return true
            } else e.preventDefault();
        },
        sexagesimalIsValid(value) {
            if (typeof(value) != "string") {
                return false
            }
            try {
                let values = value.split(":").map((obj) => parseFloat(obj))
                if (values.length != 3){
                    return false
                }
                if (values.some(obj=>isNaN(obj))){
                    return false
                }
                return true
            } catch {
                return false
            }
        },
        decimal2sexagesimal(value) {
            if (value === null) {
                return null
            }
            let isNegative = value < 0;
            if (isNegative) {
                value = -value;
            }
            let degrees = Math.floor(value);
            let minutes = Math.floor((value % 1) * 60);
            let seconds = parseFloat((((value * 60) % 1) * 60).toFixed(4));
            return `${this.hours?'':(isNegative?'-':'+')}${zeroPad(degrees,2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`
        },
        sexagesimal2decimal(value) {
            if (this.sexagesimalIsValid(value)) {
                let [degrees, minutes, seconds] = value.split(":").map((obj) => parseFloat(obj));
                if (value[0] != '-') {
                    return degrees + minutes / 60 + seconds / 3600
                } else {
                    return degrees - minutes / 60 - seconds / 3600
                }
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler() { this.localValue = this.decimal2sexagesimal(this.value) },
        },
        localValue() {
            if (this.sexagesimalIsValid(this.localValue)) {
                this.$emit('input', this.sexagesimal2decimal(this.localValue))
            }
        },
    }
}
</script>

<style scoped>
</style>
