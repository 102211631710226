<template>
    <v-dialog v-model="dialog" max-width="1100">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on"><v-icon small>mdi-eye</v-icon></v-btn>
        </template>
        <v-card v-if="obj" :loading="loading">
            <v-card-title class="text-h5">
                {{ $t('Observing Run') }} #{{ id }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-card color="primary lighten-5" elevation="0">
                        <v-card-title class="text-overline pb-0 justify-center">{{ $t('Name') }}</v-card-title>
                        <v-card-text class="text-center"> {{ obj.target_name }}</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card color="primary lighten-5" elevation="0">
                        <v-card-title class="text-overline pb-0 justify-center">{{ $t('Ascension') }}</v-card-title>
                        <v-card-text class="text-center"> {{ obj.ra|decimal2sexagesimal }} </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card color="primary lighten-5" elevation="0">
                        <v-card-title class="text-overline pb-0 justify-center">{{ $t('Declination') }}</v-card-title>
                        <v-card-text class="text-center"> {{ obj.dec|decimal2sexagesimal(true) }} </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card color="primary lighten-5" elevation="0">
                        <v-card-title class="text-overline pb-0 justify-center">{{ $t('Magnitude') }}</v-card-title>
                        <v-card-text class="text-center"> {{obj.mag}} </v-card-text>
                        </v-card>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-card color="primary lighten-5" elevation="0">
                                <v-card-title class="text-overline pb-0 justify-center">{{ $t('Telescope model') }}</v-card-title>
                                <v-card-text class="text-center"> {{ obj.telescope_model}} </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card color="primary lighten-5" elevation="0">
                                <v-card-title class="text-overline pb-0 justify-center">{{ $t('Camera model') }}</v-card-title>
                                <v-card-text class="text-center"> {{ obj.camera_model}} </v-card-text>
                            </v-card>
                        </v-col>
                    <v-col cols="4">
                        <v-card color="primary lighten-5" elevation="0">
                        <v-card-title class="text-overline text-center pb-0 justify-center">{{ $t('Repetitions') }}</v-card-title>
                        <v-card-text class="text-center">
                            <span v-if="obj.n_rep_block != null">{{ obj.n_rep_block }} reps</span>
                            <span v-else>{{ "Number of repetitions not defined" }}</span>
                            <span class="ml-1 font-weight-light font-italic" v-if="obj.min_cadence">(Cadence: {{ getCadence() }})</span>
                            <span class="ml-1 font-weight-light font-italic" v-if="obj.full_interval">(Full interval)</span>
                        </v-card-text>
                        </v-card>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="9">
                        <v-card color="primary lighten-5" elevation="0">
                        <v-card-title class="text-overline text-center pb-0 justify-center">{{ $t('Observing Block') }}</v-card-title>
                            <v-card-text>
                                <v-simple-table class="primary lighten-5"   >
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left"> {{ $t('Filter') }} </th>
                                            <th class="text-left"> {{ $t('SNR') }} </th>
                                            <th class="text-center"> {{ $t('Estimated ET') }} </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="line, j in obj.lines" :key="j">
                                            <td>{{ line.filter }}</td>
                                            <td>{{ line.snr }}</td>
                                            <td class="text-center">
                                            <v-chip v-if="etcOutcome.lines_times[j]" color="primary" x-small>{{ formatTime(etcOutcome.lines_times[j]) }}</v-chip>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card color="primary lighten-5" elevation="0">
                            <v-card-title class="text-overline text-center pb-0 justify-center">{{ $t('Total exposure time') }}</v-card-title>
                            <v-card-text class="text-center">{{ formatTime(totalRunTime) }}</v-card-text>
                        </v-card>
                        <v-card color="primary lighten-5" elevation="0" style="margin-top: 24px;">
                            <v-card-title class="text-overline text-center pb-0 justify-center">{{ $t('Pending DTOs (Consumed)') }}</v-card-title>
                            <v-card-text class="text-center">{{ innerParseFloat(obj.estimated_dtos)}} ({{innerParseFloat(obj.consumed_dtos)}})</v-card-text>
                        </v-card>
                    </v-col>
                    </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">
                    {{ $t('Close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { formatter } from '@/helpers/utils.js'
export default {
    name: 'ObservingRunDetails',
    props: ["id", ],
    data: function() {
        return {
            loading: false,
            dialog: false,
            lastFetchTime: null,
            etcOutcome: null,
            obj: null,
        }
    },
    computed:{
        etcForm() {
            return {
            target_magnitude: this.obj.mag,
            zenithal_distance: 30,
            lines: this.obj.lines,
            seeing: 1.3,
            sky_brightness: 20,
            telescope_model: this.obj.telescope_model,
            camera_model: this.obj.camera_model
            }
        },
        totalRunTime() {
            if (this.obj.full_interval) {
                let start = Date.parse(this.obj.min_time)
                let end = Date.parse(this.obj.max_time)
                return (end - start) / 1000
            } else {
                if (this.etcOutcome==null && this.obj.n_rep_block != null){
                    return null
                }else if (this.obj.n_rep_block != null) {
                    return this.etcOutcome.total_time * this.obj.n_rep_block;
                } else {
                    return this.obj.t_rep_block;
                }
            }
        },
    },
    methods: {
        fetch() {
            this.loading = true;
            return this.$apiRequest('get', `observing-runs/${this.id}/`).then(res => {
                this.obj = res.data;
                this.loading = false;
            })
        },
        etcFormIsValid() {
            let isValid = true;
            if (this.obj.mag == null) {
                isValid = false
            } else {
                if (!this.obj.lines) {
                    isValid = false;
                } else {
                this.obj.lines.forEach((line) => {
                    if (!line.snr || !line.filter) {
                        isValid = false
                    }
                })
                }
            }
            if (!this.obj.telescope_model || !this.obj.camera_model){
                isValid = false
            }
            return isValid
        },
        getEtcOutcome() {
            this.loading = true;
            this.$apiRequest('post', 'etc/', { data: this.etcForm }).then((res) => {
                this.etcOutcome = res.data;
                this.loading = false;
            })
        },
        formatTime(seconds) {
            if (seconds==null) {
                return "Unable to calculate exposure time with the given filters"
            }else if (this.obj.n_rep_block==null){
                return `${seconds.toFixed(2)}h`
            } else {
                if (seconds > 3600) {
                    return `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}min ${Math.floor(seconds % 60)}s`
                } else if (seconds > 60) {
                    return `${Math.floor(seconds / 60)}min ${Math.floor(seconds % 60)}s`
                } else {
                    return `${seconds.toFixed(2)}s`
                }
            }
        },
        getCadence() {
            if (this.obj.min_cadence<12){
                return `${this.obj.min_cadence} hours`
            } else if (this.obj.min_cadence>12 && this.obj.min_cadence<7*24-8){
                return `${Math.floor((this.obj.min_cadence + 8)/24)} days`
            }else if (this.obj.min_cadence<7*24-8 && this.obj.min_cadence<24*30-8){
                return `${Math.floor((this.obj.min_cadence + 8)/(24*7))} weeks`
            }else if (this.obj.min_cadence<24*30-8 && this.obj.min_cadence<24*365-8){
                return `${Math.floor((this.obj.min_cadence + 8)/(24*30))} months`
            }else if (this.obj.min_cadence>24*365-8){
                return `${Math.floor((this.obj.min_cadence + 8)/(24*365))} years`
            }
        },  
        innerParseFloat(float){
            return formatter.format(float)
        }
    },
    mounted() {
        this.fetch().then(()=>{
            if (this.etcFormIsValid()){
                this.getEtcOutcome();
            } else {
                this.etcOutcome==false
            }
        })
    },
}
</script>

<style scoped>
</style>