<template>
  <div>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-card :loading="loading" class="ma-3" v-if="obj" flat>
      <v-card-title class="d-flex justify-space-between">
        <div>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn color="secondary" small icon @click="$router.back()" v-on="on" class="mr-3"><v-icon>mdi-arrow-left</v-icon></v-btn>
            </template>
            <span>{{ $t("Go back") }}</span>
          </v-tooltip>
          <v-tooltip right>
            <template #activator="{ on }">
              <v-btn color="secondary" icon @click="fetch()" v-on="on" class="mr-5"><v-icon>mdi-refresh</v-icon></v-btn>
            </template>
            <span>{{ $t("Refresh") }}</span>
          </v-tooltip>
          <span>{{ $t("Proposal") }}&nbsp;#{{ id }}</span>
        </div>
        <div>
          <economic-evaluation v-if="(obj.status == 'scientifically_approved') && $store.getters['auth/isEconomicCommittee'] && !this.isOrganizationFunc()" :obj="obj" @done="fetch()">
            <template #activator="{ on }">
              <v-btn class="mx-1" color="secondary" v-on="on" outlined>
                <v-icon small>mdi-flask</v-icon>&nbsp;{{ $t('Economic evaluation') }}
              </v-btn>
            </template>
          </economic-evaluation>
          <scientific-evaluation v-if="(obj.status == 'pending') && $store.getters['auth/isScientificCommittee'] && !this.isOrganizationFunc()" :obj="obj" @done="fetch()">
            <template #activator="{ on }">
              <v-btn class="mx-1" color="secondary" v-on="on" outlined>
                <v-icon small>mdi-flask</v-icon>&nbsp;{{ $t('Scientific evaluation') }}
              </v-btn>
            </template>
          </scientific-evaluation>
          <cancel-proposal v-if="(obj.status == 'approved') && ($store.state.auth.user.username == obj.applicant) && !this.isOrganizationFunc()" :obj="obj" @done="fetch()">
            <template #activator="{ on }">
              <v-btn class="mx-1" color="red" v-on="on" outlined>
                <v-icon small>mdi-cancel</v-icon>&nbsp;{{ $t('Cancel') }}
              </v-btn>
            </template>
          </cancel-proposal>
          <delete-proposal v-if="(obj.status == 'pending') && ($store.state.auth.user.username == obj.applicant) && !this.isOrganizationFunc()" :obj="obj" @done="$router.push({name:'home'})">
            <template #activator="{ on }">
              <v-btn class="mx-1" color="red" v-on="on" outlined>
                <v-icon small>mdi-delete</v-icon>&nbsp;{{ $t('Delete') }}
              </v-btn>
            </template>
          </delete-proposal>
          <close-proposal v-if="(obj.status == 'approved') && ($store.state.auth.user.username == obj.applicant) && !this.isOrganizationFunc()" :obj="obj" @done="fetch()">
            <template #activator="{ on }">
              <v-btn class="mx-1" v-on="on" outlined>
                <v-icon small>mdi-package</v-icon>&nbsp;{{ $t('Close') }}
              </v-btn>
            </template>
          </close-proposal>
          <OperateDTO @done="fetch" v-if="this.isOrganization"
            :id="this.id"
            :title="this.obj.title"
            :assigned_dtos="this.obj.assigned_dtos"
            :organization ="this.obj.organization"
            :observing_runs = "this.obj.observing_runs"
            :status ="this.obj.status"
            operation = "add"
          />
          <OperateDTO @done="fetch" v-if="this.isOrganization"
            :id="this.id"
            :title="this.obj.title"
            :assigned_dtos="this.obj.assigned_dtos"
            :organization ="this.obj.organization"
            :status ="this.obj.status"
            operation = "remove"
          />
        </div>
      </v-card-title>
      <v-divider />
      <v-tabs v-model="tab" vertical>
        <v-tab>{{ $t('Description') }}</v-tab>
        <v-tab v-if="!this.isOrganizationFunc()">{{ $t('Observing Runs') }}</v-tab>
        <v-tab v-if="!this.isOrganizationFunc()">{{ $t('Images') }}</v-tab>
        <v-tab v-if="!this.isOrganizationFunc()">{{ $t('History') }}</v-tab>
        <v-tab v-if="!this.isOrganizationFunc() & userHasPermission()">{{ $t('Users') }}</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field :label="$t('Title')" readonly filled :value="obj.title" hide-details />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <p class="text--primary text-subtitle-2" v-text="$t('Justification')"></p>
                    <p>{{ obj.justification }}</p>
                  </v-col>
                  <v-col cols="4">
                    <v-card elevation="1">
                      <v-simple-table class="striped" >
                        <tbody>
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Status') }}</td>
                            <td class="text-right"><status-chip small :value="obj.status" kind="proposal" /></td>
                          </tr>
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Applicant') }}</td>
                            <td class="text-right">{{ obj.applicant }}</td>
                          </tr>                        
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Organization') }}</td>
                            <td class="text-right">{{ obj.organization }}</td>
                          </tr>
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Ref. ID') }}</td>
                            <td class="text-right">{{ obj.id }}</td>
                          </tr>
                          <tr>
                            <v-tooltip bottom color="primary">
                              <template v-slot:activator="{ on }">
                                <td v-on="on" class="text-left text-subtitle-2">{{ $t('Assigned DTOs') }}</td>
                                <td v-on="on" class="text-right">{{innerParseFloat(obj.assigned_dtos)}} {{ $t('DTOs') }}</td>
                              </template>
                                <div v-if="Object.keys(groupedAssignments).length === 0">
                                  <span>{{ $t('No assigned DTOs') }}</span>
                                </div>
                                <div v-else>
                                  <span style="font-weight: bold">{{ $t('Assigned DTOs per year:') }}</span>
                                  <ul v-for="(amount, year) in groupedAssignments" :key="year">
                                    <li>{{ year }}: {{ amount }} {{ $t('DTOs') }}</li>
                                  </ul>
                                </div>
                            </v-tooltip>
                          </tr>
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Pending DTOs') }}</td>
                            <td class="text-right">{{innerParseFloat(obj.pending_dtos) }} {{ $t('DTOs') }}</td>
                          </tr>
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Available DTOs') }}</td>
                            <td class="text-right" :style="{ color: obj.available_dtos < 0 ? 'red' : 'black' }">{{innerParseFloat(obj.available_dtos)}} {{ $t('DTOs') }}</td>
                          </tr>
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Consumed DTOs') }}</td>
                            <td class="text-right">{{innerParseFloat(obj.consumed_dtos)}} {{ $t('DTOs') }}</td>
                          </tr>
                          <!-- <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Consumed time') }}</td>
                            <td class="text-right">{{ obj.consumed_time }}</td>
                          </tr> -->
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Total exposure time') }}</td>
                            <td class="text-right">{{ parseTime(this.obj.observed_time) }}</td>
                          </tr>
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Created') }}</td>
                            <td class="text-right">{{ $d(new Date(obj.created), 'short') }}</td>
                          </tr>
                          <tr>
                            <td class="text-left text-subtitle-2">{{ $t('Last updated') }}</td>
                            <td class="text-right">{{ $d(new Date(obj.updated), 'short') }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-alert :border="'top'" colored-border type="info" elevation="2" v-if="(obj.status == 'pending') || (obj.status == 'scientifically_approved')">
                  {{ $t("Observing runs can't be added until proposal has been approved") }}
                </v-alert>
                <v-data-table  group-by="mosaic" :group-expand="[]" :headers="observingRunsTableHeaders" :items="obj.observing_runs" class="striped" :no-data-text="$t('No observing runs yet')">
                  <template v-slot:group.header="item">
                      <td :colspan="10">
                        <div class="d-flex align-center"  >
                          <v-btn icon @click="item.toggle">
                            <v-icon variant="text">{{ item.isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                          </v-btn>
                          <span v-if="item.group">{{ $t('Mosaic') }} {{ item.group }} | {{ item.items[0].target_name }}</span>
                          <span v-else>{{ $t('Singles') }}</span>
                          <v-btn v-if="item.group && !item.items.some(object => object.status== 'in_progress') && item.items.some(object => object.status== 'pending') && !item.items.some(object => object.status== 'done') " icon @click="{showDialogDelete=true; targetMosaic=item.group}" class="ml-auto " style="margin-right: -3px;">
                            <v-icon size="large" variant="text">{{ 'mdi-delete' }}</v-icon>
                          </v-btn>
                          <v-btn v-if="item.group && item.items.some(object => object.status== 'in_progress')" icon @click="{showDialogClose=true; targetMosaic=item.group}" class="ml-auto " style="margin-right: -3px;">
                            <v-icon size="large" variant="text">{{ 'mdi-stop' }}</v-icon>
                          </v-btn>
                      </div>
                      </td> 
                  </template>
                  
                  <template #[`footer.prepend`]>
                    <new-observing-run :proposal="obj" v-if="(obj.status == 'approved') && userHasPermission()" @done="fetch()">
                      <template #activator="{ on }">
                        <v-btn outlined color="primary" v-on="on">{{ $t('Add observing run') }}</v-btn>
                      </template>
                    </new-observing-run>
                  </template>
                  <template #[`item.filters`]="{ item }">
                    {{ getFilters(item) }}
                  </template>
                  <template #[`item.status`]="{ item }">
                    <status-chip small :value="item.status" kind="observing-run" />
                  </template>
                  <template #[`item.dto`]="{ item }">
                  <template >
                    {{ innerParseFloat(item.consumed_dtos) }} | {{ innerParseFloat(item.estimated_dtos) }} 
                  </template>
                  </template>
                  <template #[`item.progress`]="{ item }">
                      <v-progress-linear :value="item.progress" color="primary lighten-3" striped height="25">
                        <template v-slot:default="{ value }">
                          {{ Math.ceil(value) }}%
                        </template>
                      </v-progress-linear>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <observing-run-details :id="item.id" />
                    <observing-run-delete v-if="item.status == 'pending'" :id="item.id" @done="fetch"/>
                    <observing-run-stop v-if="item.status == 'in_progress'" :id="item.id" @done="fetch"/>
                  </template>
                </v-data-table>  
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols="9">
                <proposal-images :id="id" />
              </v-col>
              <v-col cols="3">
                <v-alert :border="'top'" colored-border type="info" elevation="2" class="my-3">
                  <p>For massive downloads, you may want to try our SFTP server:</p>
                  <ul>
                    <li><span class="font-weight-bold">Host</span>: dto.ttt.lightbridges.es</li>
                    <li><span class="font-weight-bold">Port</span>: 22</li>
                    <li><span class="font-weight-bold">Username</span>: downloader</li>
                    <li><span class="font-weight-bold">Password</span>: no-password</li>
                  </ul>
                  <br/>
                  <p>
                    You won't be able to list the root directory, but you will be able to access to folder
                    <span class="font-weight-bold">/{{ obj.uuid }}</span> where images for this proposal will be available.
                    Keep in mind that sharing this UUID will give public access to your images.
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table :headers="historyTableHeaders" :items="obj.history" class="striped">
                  <template #[`item.date_created`]="{ item }">
                    {{ $d(new Date(item.date_created), 'long') }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
              <v-card flat>
              <v-card-text>
                <div class="d-flex">
                <v-text-field class="mb-5"
                  v-model="add_email_user"
                  :label="$t('Add User By Email')"
                  outlined hide-details
                  :placeholder="$t('Email')"
                />    
                <v-btn color="primary" x-large text @click="addUserByEmail()">
                  {{ $t("Add") }}   
                 <span style="margin-left: 10px;"></span>
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
                </div>
                <v-data-table :headers="associatedUsersHeaders" :items="associatedUsers" class="striped" :key="tableKey">
                  <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon @click="deleteAssociatedUser(item.email)" class="ml-auto" style="margin-right: -3px;">
                    <v-icon size="large" variant="text">mdi-delete</v-icon>
                  </v-btn>
                </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
    <v-dialog v-if="showDialogDelete==true" v-model="showDialogDelete" max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Confirm Deletion') }}
        </v-card-title>
        <v-card-text>
          {{ $t('Are you sure you want to delete all the observing runs of this mosaic?') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="showDialogDelete = false"> {{ $t('Cancel') }} </v-btn>
          <v-btn color="primary" text @click="deleteMosaic()"> {{ $t('Confirm') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="showDialogClose==true" v-model="showDialogClose" max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Confirm Stop') }}
        </v-card-title>
        <v-card-text>
          {{ $t('Are you sure you want to stop all the observing runs of this mosaic?') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="showDialogClose = false"> {{ $t('Cancel') }} </v-btn>
          <v-btn color="primary" text @click="stopMosaic()"> {{ $t('Confirm') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CancelProposal from '@/components/proposals/CancelProposal.vue'
import DeleteProposal from '@/components/proposals/DeleteProposal.vue'
import ScientificEvaluation from '@/components/proposals/ScientificEvaluation.vue'
import EconomicEvaluation from '@/components/proposals/EconomicEvaluation.vue'
import ProposalImages from '@/components/proposals/ProposalImages.vue'
import NewObservingRun from '@/components/runs/NewObservingRun.vue'
import CloseProposal from '@/components/proposals/CloseProposal.vue'
import StatusChip from '../components/utils/StatusChip.vue'
import ObservingRunDetails from '@/components/runs/ObservingRunDetails.vue';
import ObservingRunDelete from '@/components/runs/ObservingRunDelete.vue';
import ObservingRunStop from '@/components/runs/ObservingRunStop.vue';
import OperateDTO from '../components/proposals/OperateDTO.vue'
import { parseTime, formatter } from '@/helpers/utils.js'

export default {
  name: 'ProposalDetails',
  components: { 
    CancelProposal, ScientificEvaluation, EconomicEvaluation, NewObservingRun,
    CloseProposal, ProposalImages, StatusChip, ObservingRunDetails, ObservingRunDelete, DeleteProposal, ObservingRunStop,
    OperateDTO
  },
 
  data: function() {
    return {
       associatedUsers: [],
      add_email_user: "",
      showDialogDelete:false,
      showDialogClose:false,
      targetMosaic:null,
      tableKey: 0,
      loading: false,
      tab: "summary",
      id: this.$route.params.id,
      isOrganization: this.$route.params.isOrganization,
      obj: null,
      filterChoices: [],
      groupedAssignments: {},
      historyTableHeaders: [
        { value: 'date_created', text: this.$i18n.t('Date'), align: 'left', sortable: true },
        { value: 'user', text: this.$i18n.t('User'), align: 'center', sortable: true },
        { value: 'comment', text: this.$i18n.t('Action'), align: 'right', sortable: false },
      ],
      associatedUsersHeaders: [
        { value: 'username', text: this.$i18n.t('User'), align: 'left', sortable: true },
        { value: 'email', text: this.$i18n.t('Email'), align: 'left', sortable: true },
        { value: 'actions', text: '', align: 'right', sortable: false },
      ],
      observingRunsTableHeaders: [
        { value: 'id', text: '#', align: 'center', sortable: false, divider: true, width: 80, },
        { value: 'mosaic', text: this.$i18n.t('Mosaic'), align: 'left', sortable: true },
        { value: 'target_name', text: this.$i18n.t('Target'), align: 'left', sortable: true },
        { value: 'filters', text: this.$i18n.t('Filters'), align: 'center', sortable: true },
        { value: 'status', text: this.$i18n.t('Status'), align: 'center', sortable: true },
        { value: 'observed_time', text: this.$i18n.t('Observed time'), align: 'center', sortable: true },
        { value: 'exposed_time', text: this.$i18n.t('Exposed time'), align: 'center', sortable: true },
        
        { value: 'n_images', text: this.$i18n.t('Observed images'), align: 'center', sortable: true },
        { value: 'progress', text: this.$i18n.t('Progress'), align: 'center', sortable: true },
        { value: 'dto', text: this.$i18n.t('DTOs'), align: 'center', sortable: false },
        { value: 'actions', text: '', align: 'right', sortable: false },
      ],
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      var thisFetchTime = this.lastFetchTime = Date.now()
      this.$apiRequest('get', `proposals/${this.$route.params.id}/`, {}, [404, 403]).then(res => {
        if (this.lastFetchTime <= thisFetchTime) {
          this.obj = res.data;
          this.groupAssignments();
          this.loading = false;
          this.obj.observing_runs.forEach(ob => {
            ob.observed_time = parseTime(ob.observed_time)
            ob.exposed_time = parseTime(ob.exposed_time)
          })
        }
      }).catch(() => {
        this.$router.push("notfound");
      })
      this.fetchAssociatedUsers()
      

    },
    fetchAssociatedUsers(){
      this.$apiRequest('get', `associated-users/`, {params: {
          id: this.$route.params.id,
        }
      }, [404, 403]).then(res => {
        this.associatedUsers = res.data.users
        this.tableKey += 1;
      }).catch(() => {
        this.$router.push("notfound");
      })
    },
    userHasPermission(){
      if ((this.$store.state.auth.user.username == this.obj.applicant) 
      | (this.associatedUsers.some(user => user.username === this.$store.state.auth.user.username) )){
        return true
      }
      return false 
    },
    addUserByEmail(){
      this.$apiRequest('post', `associated-users/`, {
        data: {
          "proposal_id": this.$route.params.id,
          "user_email": this.add_email_user
        }
            
      }, [404, 403,400]).then(() => {
        this.add_email_user = ""
        this.fetchAssociatedUsers()
        this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("User Added")
          });
      }).catch(() => {
        this.$store.dispatch('alert/alert', {
            type: "error",
            message: this.$t("User Not Found")
          });
        
      })
      
    },
    deleteAssociatedUser(item){
      this.loading = true;
      this.$apiRequest('delete', `associated-users/`, {
        data: {
          "proposal_id": this.$route.params.id,
          "user_email": item
        }
            
      }).then(() => {
          this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("User succesfully unlinked")
          });
          this.loading = false;
          this.fetchAssociatedUsers();
          this.$emit("done");
          })
    },
    openDialogDelete(item){
      this.currentMosaicDeleteTarget = item;
    },
    stopMosaic(){
      this.loading = true;
      this.$apiRequest('post', 'mosaics/'+
      this.targetMosaic+'/close/').then(() => {
          this.showDialogClose = false;
          this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("Mosaic succesfully closed")
          });
          this.loading = false;
          this.showDialogClose = false;
          this.fetch();
          this.$emit("done");
          })
    },
    deleteMosaic(){
      this.targetMosaic
      this.loading = true;
      this.$apiRequest('delete', 'mosaics/'+
      this.targetMosaic+'/').then(() => {
          this.showDialogDelete = false;
          this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("Mosaic succesfully deleted")
          });
          this.loading = false;
          this.fetch();
          this.$emit("done");
          })
    },
    getFilters(item) {
      var filters = ""
      for (const line of item.lines) {
        if (!filters.includes(line.filter)){
          filters = filters.concat(line.filter,",")
        }
      }
      return filters.substr(-filters.lenght, filters.length-1)
    },
    isOrganizationFunc(){
      if (typeof this.isOrganization === 'undefined' || this.isOrganization === null) {
        return false;
      } else {
        return this.isOrganization;
      }
    },
    groupAssignments() {
      this.groupedAssignments = {};

      if(this.obj.assignments){
        this.obj.assignments.forEach(assignment => {
          if (!this.groupedAssignments[assignment.year]) {
            this.groupedAssignments[assignment.year] = 0;
          }
          this.groupedAssignments[assignment.year] += parseFloat(assignment.amount);
        });
      }
    },
    parseTime(time){
      return parseTime(time)
    },
    innerParseFloat(float){
      return formatter.format(float)
    }
  },
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
</style>
