var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"striped",attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [10, 20, 50] },"options":_vm.options,"server-items-length":_vm.totalItems,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Search'),"placeholder":_vm.$t('Search by ID, filter name, or target'),"append-icon":"mdi-magnify","filled":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-datetime-picker',{attrs:{"date-format":"yyyy-MM-dd","time-format":"HH:mm","label":_vm.$t('Since'),"text-field-props":{ hideDetails: true, filled: true, dense: true, appendIcon: 'mdi-calendar' },"date-picker-props":{ noTitle: true, },"time-picker-props":{ noTitle: true, format: '24hr' }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-calendar")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock")])]},proxy:true},{key:"actions",fn:function(ref){
var parent = ref.parent;
return [_c('v-btn',{attrs:{"text":"","color":"secondary"},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Clear')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":parent.okHandler}},[_vm._v(_vm._s(_vm.$t('Ok')))])]}}]),model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-datetime-picker',{attrs:{"date-format":"yyyy-MM-dd","time-format":"HH:mm","label":_vm.$t('Until'),"text-field-props":{ hideDetails: true,  filled: true, dense: true, appendIcon: 'mdi-calendar' },"date-picker-props":{ noTitle: true, },"time-picker-props":{ noTitle: true, format: '24hr' }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-calendar")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock")])]},proxy:true},{key:"actions",fn:function(ref){
var parent = ref.parent;
return [_c('v-btn',{attrs:{"text":"","color":"secondary"},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Clear')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":parent.okHandler}},[_vm._v(_vm._s(_vm.$t('Ok')))])]}}]),model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.statusChoices,"label":_vm.$t('Status'),"filled":"","dense":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":item.color}},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"small":"","value":item.status,"kind":"observing-line"}})]}},{key:"item.image_public_url",fn:function(ref){
var item = ref.item;
return [(item.image_public_url)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"href":item.image_public_url,"icon":""}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }