<template>
  <v-dialog v-model="dialog" width="600">
    <template #activator="{ on: dialog }">
      <slot name="activator" :on="dialog" />
    </template>
    <v-card :loading="loading">
      <v-card-title class="text-h5 mb-5">{{ $t("Scientific evaluation") }}</v-card-title>
      <v-card-text>
        <v-slider v-model="form.scientific_merit"
          :label="$t('Scientific merit')" :min="0" :max="10" thumb-label="always"
          :hint="$t('If scientific merit is 0 then proposal will be rejected')" persistent-hint
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="dialog = false">{{ $t('Close') }}</v-btn>
        <v-btn color="primary" text @click="submit">{{ form.scientific_merit?$t('Approve'):$t('Reject') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ScientificEvaluation",
  props: [ "obj" ],
  data: function() {
    return {
      dialog: false,
      loading: false,
      form: {
        scientific_merit: null,
      },
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.$apiRequest('post', `proposals/${this.obj.id}/scientific-approve/`, { 
        data: this.form,
      }).then(() => {
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Proposal succesfully approved")
        });
        this.loading = false;
        this.dialog = false;
        this.$emit("done");
      })
    }
  },
}
</script>

<style scoped>
</style>
