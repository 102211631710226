<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <span>{{ $t('Observing runs') }}</span>
    </v-card-title>
    <v-data-table :loading="loading" :items="items" class="striped" :headers="headers"
      :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
      :options.sync="options" :server-items-length="totalItems" :items-per-page="itemsPerPage" >
      <template #[`item.status`]="{ item }">
        <v-chip dark small :color="statusData(item.status).color">{{ statusData(item.status).text }}</v-chip>
      </template>
      <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template #[`item.filters`]="{ item }">
        {{ getFilters(item) }}
      </template>
      <template #[`footer.prepend`]>
        <v-btn small color="secondary" icon @click="fetch"><v-icon small>mdi-refresh</v-icon></v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import constants from "@/helpers/constants";

export default {
  name: "AllRuns",
  data: function() {
    return {
      items: [],
      headers: [
        { value: 'id', text: '#', align: 'center', sortable: true, divider: true, width: 80, },
        { value: 'target_name', text: this.$i18n.t('Target'), align: 'left', sortable: true },
        { value: 'proposal', text: this.$i18n.t('Proposal'), align: 'center', sortable: true },
        { value: 'filters', text: this.$i18n.t('Filters'), align: 'center', sortable: false },
        { value: 'status', text: this.$i18n.t('Status'), align: 'center', sortable: true },
      ],
      loading: false,
      totalItems: 0,
      itemsPerPage: 0,
      lastFetchTime: null,
      selectedItem: null,
      options: {},
    }
  },
  computed: {
    filterset() {
      let orderings = [];
      if (this.options.sortBy) {
        for (var i=0;i<this.options.sortBy.length;i++) {
          orderings.push((this.options.sortDesc[i]?'':'-') + this.options.sortBy[i]);
        }
      }
      return {
        ...this.filters,
        ordering: orderings.join(","),
        page: this.options.page,
        page_size: this.options.itemsPerPage,
      }
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      var thisFetchTime = this.lastFetchTime = Date.now()
      this.$apiRequest('get', "observing-runs/",{params: this.filterset,} ).then(res => {
        if (this.lastFetchTime <= thisFetchTime) {
          this.items = res.data.results;
          this.totalItems = res.data.count;
          this.itemsPerPage = res.data.results.length;
        }
      }).finally(() => (this.loading = false))
    },
    statusData(status) {
      return constants.OBSERVING_RUN_STATUS_CHOICES.find((obj) => obj.value == status);
    },
    getFilters(item) {
      var filters = ""
      for (const line of item.lines) {
        if (!filters.includes(line.filter)){
          filters = filters.concat(line.filter,",")
        }
      }
      return filters.substr(-filters.lenght, filters.length-1)
    },
  },
  watch: {
    filterset: {
      handler (newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          this.$nextTick(this.fetch);
        }
      },
    },
  },
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
</style>
