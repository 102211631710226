<template>
    <v-dialog v-model="dialog" persistent max-width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on"><v-icon small>mdi-stop</v-icon></v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5">
                {{$t("Are you sure you want to stop observing run #{id}?", {id:this.id})}}
            </v-card-title>
            <v-card-text> 
                {{$t("Stopping the Observing Run will modify it's status as Done, and will no longer be planned.")}}
            </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="error"
                        class="ma-2 white--text"
                        @click="stopObservinRun()"
                    >
                        Stop
                    </v-btn>
                </v-card-actions>
            </v-card>
        
    </v-dialog>
</template>

<script>
export default {
    name: 'ObservingRunStop',
    props: ["id", ],
    data: function() {
        return {
            loading: false,
            dialog: false,
            obj: null,
        }
    },
    methods: {
        stopObservinRun() {
            this.loading = true;
            this.$apiRequest('post', `observing-runs/${this.id}/close/`).then(()=> {
                this.loading = false;
                // this.$store.dispatch('alert/alert', {
                //     type: "info",
                //     message: this.$t("Observing run succesfully closed")
                // })
                this.dialog = false;
                this.$emit("done");
            });
        }
    },
}
</script>

<style scoped>
</style>