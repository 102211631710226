<template>
  <v-dialog v-model="dialog" width="800">
    <template #activator="{ on, attrs }">
      <v-btn class="mx-1" @click="resetValidation()" color="primary" text outlined v-bind="attrs" v-on="on">{{ $t("New Endorsement") }}</v-btn>
    </template>
    <v-card :loading="loading">
      <v-form lazy-validation ref="formEndoso">
        <v-card-title class="text-h5 mb-5">{{ $t('DTO transfer') }}</v-card-title>
        <v-card-text>
          <!-- Organizations List -->
          <v-row>
            <v-col cols="12">
              <v-autocomplete 
                outlined 
                :label="$t('Organization')" 
                v-model="form.to_organization" 
                :items="this.getOrganizationsListName()" 
                :rules="orgRules"
                clearable
                search
              >
                <template v-slot:append>
                  <v-icon color="primary">mdi-account-group</v-icon>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Validity -->
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="yearSelected.year"
                :items="years"
                :label="$t('Validity Until')"
              >
                <template v-slot:append>
                  <v-icon color="primary">mdi-calendar-blank</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- DTO's Amoung -->
          <v-row>
            <v-col cols="12" v-if="this.isVisible()">
              <v-text-field 
                outlined 
                :label="$t('DTO')" 
                v-model="form.amount" 
                type="number"
                suffix="DTO" 
                step="0.01"
                min="0.01" 
                :max="this.availableDto"
                :rules="rules"
                :hint="this.$t('DTO available', { value: this.availableDto })"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Botones -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="close()">{{ $t('Close') }}</v-btn>
          <v-btn color="primary" text @click="submit">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
      name: 'NewEndorsement',    
      watch: {
        'yearSelected.year'() {
          this.setValidityDate();
          this.getNumDTOAvailable();
        },
      },
      data: function() {
          return {
            yearSelected: {
              year: null,
              date: null,
            },
            years: [],
            dialog: false,
            loading: false,
            myOrganization: null,
            organizations: null,
            form: {
              to_organization: null,
              amount: 0,
              validity: null,
            },
            availableDto: 0,
            rules: [],
            orgRules: [],
          }
      },
      created(){
        this.setYearsArray();
        this.setValidityDate();
        this.fetchMyOrganizations();
      },
      mounted() {
        this.activateRules();
      },
      computed: {

      },
      methods: {
        setYearsArray(){
          const currentYear = new Date().getFullYear();
          this.years = Array.from({ length: 5 }, (_, i) => currentYear + i);
          this.yearSelected.year = this.years[0];
        },
        setValidityDate(){
          this.yearSelected.date = this.yearSelected.year+"-12-31T00:00:00Z";
          this.form.validity = this.yearSelected.date;
        },
        fetchOrganizations(){
          this.loading = true;
          this.$apiRequest('get', 'organizations/').then((res) => {
            this.organizations = res.data;
          }).finally(() => {
            this.loading = false;
            this.popMyOrganization();
          })
        },
        popMyOrganization(){
          this.organizations = this.organizations.filter(item => item.name !=this.myOrganization);
        },
        fetchMyOrganizations(){
          this.loading = true;
          this.$apiRequest('get', 'organizations/admin/').then((res) => {
            this.myOrganization = res.data.name;
          }).finally(() => {
            this.fetchOrganizations();
            this.getNumDTOAvailable();
          })
        },
        getOrganizationsListName() {
          if(this.organizations !== null){
            return this.organizations.map(org => org.name);
          }
          return [];
        },
        getOrganizationsId(name) {
            return this.organizations.filter(item =>item.name==name).map(org => org.id).reduce((accum, currVal) => accum+ currVal, 0);
        },
        submit() {
          if (this.$refs.formEndoso && this.$refs.formEndoso.validate()) {
            this.loading = true;
            this.checkForm();
            // console.log("[abe] - form: ",this.form);
            this.$apiRequest('post', "endorsement-request/", { 
              data: this.form,
            }).then((data) => {
              // console.log("[abe] - Respuesta: ",data);
              if(typeof data != 'undefined'){
                this.$store.dispatch('alert/alert', {
                  type: "info",
                  message: this.$t("Operation completed successfully")
                });
              }else{
                this.$store.dispatch('alert/alert', {
                  type: "error",
                  message: this.$t("Operation error")
                });
              }
              this.loading = false;
            }).finally(() => {
              this.dialog = false;
              this.$emit("done");
              this.getNumDTOAvailable();
              this.resetForm();
            })
          }
        },
        isVisible(){
          return !!this.form.to_organization ;
        },
        activateRules() {
          let auxOrgRules = [];
          let auxRules = [];
          let required = v => v != null || this.$t("The field is required");
          let numeric = v =>
            (v != null && /^-?\d+(\.\d*)?$/.test(v)) ||
            this.$t("The field must be numeric");
          let positive = v =>
            (v != null && v>0) ||
            this.$t("The field must be positive");
          let  max = v =>
          (v != null && v<=this.availableDto) ||
          this.$t("Enough DTO", { value: this.availableDto });
          
          auxOrgRules.push(required)
          auxRules.push(required, positive, numeric, max); 
          Object.assign(this.orgRules, auxOrgRules);
          Object.assign(this.rules, auxRules);
        },
        getNumDTOAvailable() {
          this.loading = true;
          this.availableDto = 0;
          if (this.yearSelected.year !== null && this.myOrganization !== null) {
            return this.$apiRequest('get', 'organizations/available/', { params: { year: this.yearSelected.year, name: this.myOrganization } }, [404, 403])
              .then(res => {
                this.availableDto = res.data;
              }).finally(() => {
                this.loading = false;
                if(typeof this.$refs.formEndoso !=="undefined"){
                  this.$refs.formEndoso.validate();
                }
              })
          }
        },
        checkForm(){
          this.form.to_organization = this.getOrganizationsId(this.form.to_organization);
        },
        resetForm(){
          // this.$refs.formEndoso.reset();
          this.form.to_organization = null
          this.yearSelected.year = this.years[0];
          this.form.amount = 0;
          this.$refs.formEndoso.resetValidation();
        },
        resetValidation(){
          if(typeof this.$refs.formEndoso !== "undefined"){
            this.$refs.formEndoso.resetValidation();
          }
        },
        close(){
          this.dialog = false;
          this.resetForm();
        },
      }
  }
</script>

<style scoped>
</style>