<template>
  <v-container fluid fill-height fill-width class="justify-center">
    <div class="text-center">
        <v-img alt="Not found" class="mx-auto shrink my-5" contain src="@/assets/img/sad_face.png"
                    transition="scale-transition" width="200"/>
        <p class="text-h1 font-weight-bold my-5">404</p>
        <p class="text-h5">{{ $t("Page Not Found: {path}", {path: this.$router.currentRoute.path}) }}</p>
        <p class="text-caption">{{ $t("The page you requested could not be found") }}</p>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'PageNotFoundView',
  }
</script>
