import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.teal,
        accent: colors.lime,
        error: colors.red.darken4,
        success: colors.lightGreen.darken3,
      },
      dark: {
        primary: colors.teal,
        accent: colors.lime,
        error: colors.red.darken4,
        success: colors.lightGreen.darken3,
      },
    },
    options: {
      customProperties: true
    }
  },
});
