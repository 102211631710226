var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('DTO endorsement')))]),_c('NewEndorsement',{on:{"done":_vm.fetch}})],1),_c('v-divider'),_c('v-data-table',{staticClass:"striped",attrs:{"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [10, 20, 50] },"items":_vm.filteredItemsTable,"search":_vm.filters.search,"loading":_vm.loading,"sort-by":"id","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Search'),"placeholder":_vm.$t('Search by from/to organization, amount or validity'),"append-icon":"mdi-magnify","filled":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.itemsStatus,"label":_vm.$t('Status'),"filled":"","dense":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}}]),model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1)]},proxy:true},{key:"header.validity",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(header.text))])]}},{key:"item.validity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(_vm.getYear(item.validity)))])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(header.text))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getColor(item.status),"text-color":"white","disabled":_vm.isDisabled(item)},on:{"click":function($event){return _vm.changeStatus(item)}}},[_c('span',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(_vm.getValueStatus(item.status)))])])]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"secondary","icon":""},on:{"click":_vm.fetch}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]},proxy:true}],null,true)}),_c('DialogStatus',{key:_vm.keyDialogStatus,attrs:{"show_dialog":_vm.show_status_dialog,"data":_vm.itemSelect},on:{"done":_vm.fetch,"action":_vm.runAction,"close-status-dialog":_vm.closeStatusDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }