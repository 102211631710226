
<template>
    <v-card :loading="loading" color="yellow lighten-3">
        <v-card-title>{{ $t('Logbook on {date}', { date: this.date }) }}</v-card-title>
        <v-card-text style="min-height: 400px;" class="d-flex flex-column justify-space-around">
            <div v-if="fetching" class="text-center">
                <v-progress-circular class="mb-5" indeterminate color="yellow" />
            </div>
            <div v-if="!fetching && (obj || editMode)">
                <v-textarea color="yellow" :readonly="!editMode" :filled="!editMode" :solo="editMode" hide-details
                    v-model="value" rows="15" />
            </div>
            <div class="text-center" v-if="!fetching && !obj && !editMode">
                {{ $t('No logbook for this date') }}
            </div>
        </v-card-text>
        <v-card-actions v-if="!fetching && editMode">
            <v-btn class="mx-1 mb-3" outlined :disabled="loading" @click="cancelEdit()">
              <v-icon small>mdi-close</v-icon>&nbsp;{{ $t('Cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn class="mx-1 mb-3" outlined :disabled="loading" @click="save()">
              <v-icon small>mdi-check</v-icon>&nbsp;{{ $t('Save') }}
            </v-btn>
        </v-card-actions>
        <v-card-actions v-if="!fetching && !editMode && !obj">
            <v-spacer />
            <v-btn class="mx-1 mb-3" outlined :disabled="loading" @click="editMode = true">
                <v-icon small>mdi-pencil</v-icon>&nbsp;{{ $t('Create') }}
            </v-btn>
        </v-card-actions>
        <v-card-actions v-if="!fetching && !editMode && obj">
            <div class="text-caption font-weight-light ma-3">
                {{ $t('Last update') }} {{ moment(obj.last_update.date) }} {{ $t('by') }} <b>{{ obj.last_update.user }}</b>
            </div>
            <v-spacer />
            <v-btn class="mx-1 mb-3" outlined :disabled="loading" @click="editMode = true">
                <v-icon small>mdi-pencil</v-icon>&nbsp;{{ $t('Edit') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import * as moment from "moment";

export default {
  name: "Logbook",
  props: ["date", ],
  data() {
    return {
        loading: false,
        fetching: true,
        obj: null,
        value: null,
        editMode: false,
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
        if (this.date) {
            this.fetching = true;
            this.editMode = false;
            return this.$apiRequest('get', `logbook/${this.date}/`, {}, [404]).then(res => {
                this.obj = res.data;
                this.value = this.obj.content;
                this.fetching = false;
            }).catch(() => {
                this.obj = null;
                this.value = null;
                this.fetching = false;
            })
        }
    },
    save() {
        this.loading = true;
        return this.$apiRequest('put', `logbook/${this.date}/`, { 
            data: {
                content: this.value,
            },
        }).then((res) => {
            let message = this.obj?this.$t("Logbook updated successfully"):this.$t("Logbook created succesfully");
            this.$store.dispatch('alert/alert', { type: "info", message: message });
            this.obj = res.data;
            this.loading = false;
            this.editMode = false;
        })
    },
    cancelEdit() {
        this.value = this.obj?this.obj.content:null;
        this.editMode = false;
    },
    moment(date) {
        return moment(date).fromNow();
    },
  },
  watch: {
    date() {
        this.fetch();
    }
  },
}
</script>

<style scoped>
</style>