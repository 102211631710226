
<template>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <polyline class="mirror-cover" points="180,180 180,20"></polyline>
        <text x="150" y="15">ZD=0</text>
        <g id="tel" :transform="tel_transform">
            <polyline class="tel-structure" points="130,30 130,180 230,180 230,30"></polyline>
            <polyline class="mirror" points="130,160 130,180 230,180 230,160 220,160 210,170 180,175 150,170 140,160 130,160"></polyline>
            <polyline class="mirror-cover" points="140,160 140,113" transform="rotate(60, 140, 160)" v-if="mirrorCover"></polyline>
            <polyline class="mirror-cover" points="140,160 140,113" transform="rotate(0, 140, 160)" v-else></polyline>
            <polyline class="mirror-cover" points="220,160 220,113" transform="rotate(-60, 220, 160)" v-if="mirrorCover"></polyline>
            <polyline class="mirror-cover" points="220,160 220,113" transform="rotate(0, 220, 160)" v-else></polyline>
            <circle class="tel-structure" cx="180" cy="190" r="10"></circle>
        </g>
    </svg>
</template>

<script>

export default {
  name: "TelescopeRepresentation",
  props: ["zd", "mirror-cover"],
  data() {
    return {
    }
  },
  computed: {
    tel_transform() {
        return `rotate(${-this.zd}, 180, 180)`
    },
  },
}
</script>

<style scoped>
svg {
    width: 250px;
    height: 250px;
}
.dome-structure {
    fill: none;
    stroke: blue; 
    stroke-width: 2;
}
.tel-structure {
    fill: #ddd;
    stroke: blue; 
    stroke-width: 2;
}
.dome-slit {
    fill: #ddd;
    stroke: blue; 
    stroke-width: 2;
}

.alt {
    fill: none;
    stroke: blue;
    stroke-width: 1.5;
    stroke-opacity: 0.7;
    stroke-linecap: round;
    stroke-dasharray: 3 6;
}

.tel-pos {
    fill: none;
    stroke: #990000;
    stroke-width: 2;
}
    
.mirror {
    fill: lightgreen;
    stroke: blue; 
    stroke-width: 2;
}
.mirror-cover {
    fill: none;
    stroke: #000; 
    stroke-width: 2;
}
</style>