import { apiPath } from '../config'

export default {
  install(Vue) {
    Vue.prototype.$apiFetchAll = function(url, into, options={}) {
      return this.$apiRequest('get', url, options).then(({ data }) => {
        into.push(...data.results);
        if (data.next) {
          let relativeRoute = data.next.slice(data.next.indexOf(apiPath) + apiPath.length)
          this.$apiFetchAll(relativeRoute, into, options);
        }
      })
    }
  } 
}
