
<template>
    <v-card :loading="loading" color="blue lighten-3" :width="width">
        <iframe v-if="imageId"
            :width="width" :height="height/2 + 50"
            :src="`${basePath}app?id=${viewerId}`">
        </iframe>
        <v-card-text :style="{height: `${this.height/2 + 50}px`}" class="d-flex flex-column justify-space-around" v-else>
            <div class="text-center" v-if="!loading && !obj">
                {{ $t('Select image to show') }}
            </div>
        </v-card-text>
        <div>
            <canvas ref="canvas" :width="width" :height="height / 2" :src="canvasContent" />
        </div>
    </v-card>
</template>

<script>
import axios from 'axios';
import * as moment from "moment";
import { ginga_make_application, ginga_initialize_canvas } from './ginga_pg.js'

export default {
  name: "Ginga",
  props: ["imageId", "width", "height"],
  data() {

    return {
        loading: false,
        basePath: 'http://localhost:9914/',
        obj: null,
        // viewerId: parseInt(Math.random()*1000000),
        viewerId: 999,
        ss: [ginga_make_application, ginga_initialize_canvas ],
        // ginga_app: ginga_make_application('ws://localhost:9914/app/socket?wid=999', false),
        ws: new WebSocket('ws://localhost:9909/ginga'),
        canvasContent: null,
    }
  },
  created() {
    // axios({
    //     method: 'get',
    //     url: this.basePath + `viewer?id=${this.viewerId}&cmd=get&width=${this.width}&height=${this.height / 2}`,
    // });
    this.ws.onopen =  () => {
        this.ws.send(JSON.stringify({ auth: "asdas" }));
        this.ws.send(JSON.stringify({
            type: "run_method",
            method_name: "set_size",
            args: [],
            kwargs: { width: this.width, height: this.height },
        }));
        this.ws.send(JSON.stringify({
            type: "run_method",
            method_name: "load_image",
            args: [30],
            kwargs: {},
        }));
    }
    this.ws.onmessage = (e) => {
        var message = JSON.parse(e.data);
        console.log(message);
        if (message.type == "draw_canvas") {
            this.canvasContent = message.src;
        }
    }
    // this.ws.onclose = (e) => {
    //     console.log("ws closed", e);
    // }
  },
  mounted() {
    // setTimeout(() => ginga_initialize_canvas(this.$refs.canvas, "3", this.ginga_app), 1000);
  },
  methods: {
    moment(date) {
        return moment(date).fromNow();
    },
    loadImage() {
        return axios({
            method: 'get',
            url: this.basePath + `viewer?id=${this.viewerId}&cmd=load&obl=${this.imageId}`,
        })
    }
  },
  watch: {
    imageId() {
        if (this.imageId) {
            this.loadImage();
        }
    }
  },
}
</script>

<style scoped>
</style>