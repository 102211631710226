<template>
    <v-dialog v-model="dialog" persistent max-width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on"><v-icon small>mdi-delete</v-icon></v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5">
                {{$t("Are you sure you want to delete observing run #{id}?", {id:this.id})}}
            </v-card-title>
            <v-card-text> 
                {{$t("Delete will erase any existence of this observing run from the database.")}}
            </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="error"
                        class="ma-2 white--text"
                        @click="deleteObservinRun()"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        
    </v-dialog>
</template>

<script>
export default {
    name: 'ObservingRunDelete',
    props: ["id", ],
    data: function() {
        return {
            loading: false,
            dialog: false,
            obj: null,
        }
    },
    methods: {
        deleteObservinRun() {
            this.loading = true;
            this.$apiRequest('delete', `observing-runs/${this.id}/`).then(res => {
                this.loading = false;
                this.obj = res.data;
            }).then(() => {
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Observing run succesfully deleted")
        });
        this.loading = false;
        this.dialog = false;
        this.$emit("done");
      })
        }
    },
}
</script>

<style scoped>
</style>