<template>
  <v-dialog v-model="dialogStatus" width="800" persistent>
    <v-card :loading="loading">
      <v-form lazy-validation ref="formEndoso">
        <v-card-title class="text-h5 mb-5">{{ $t('Approve DTOs transfer', { value: data.from }) }}</v-card-title>
        <v-card-text>
          <div>{{ this.getMessage() }}</div>
        </v-card-text>
        <!-- Botones -->
        <v-card-actions v-if="this.isPending()">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="send('aprove')">{{ $t('Approve') }}</v-btn>
          <v-btn color="red" text @click="send('rejected')">{{ $t('Rejected') }}</v-btn>
          <v-btn color="secondary" text @click="close()">{{ $t('Cancel') }}</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="close()">{{ $t('Ok') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
      name: 'DialogStatus',
      props: {
        show_dialog: {
          type: Boolean
        },
        data : {
          type: Object
        }
      },
      watch: {
        show_dialog: {
          immediate: true,
          handler(newVal) {
            this.dialogStatus = newVal;
          }
        }
      },
      data: function() {
          return {
            dialogStatus: false,
            loading: false,
            transaction: {
              to_organization: null,
              from_organization: null,
              amount: 0,
              validity: null,
              type: null,
              observations: null,
            },
          }
      },
      created(){

      },
      mounted() {

      },
      computed: {

      },
      methods: {
        send(action){
          this.data.action = action;
          this.submit();
        },
        submit() {
          this.loading = true;
          // console.log("[abe] - item to submit: ",this.data.id," action: ",this.data.action," data: ",this.data);
          this.$apiRequest('put', `endorsement-request/${this.data.id}/`, { 
            data: this.data,
          }).then((data) => {

            if(typeof data != 'undefined'){
              this.$store.dispatch('alert/alert', {
                type: "info",
                message: this.$t("Operation completed successfully")
              });
            }else{
              this.$store.dispatch('alert/alert', {
                type: "error",
                message: this.$t("Operation error")
              });
            }
          }).finally(() => {
            this.loading = false;
            this.close();
            this.$emit("done");
          }).catch(() => {
            this.loading = false;
            this.$store.dispatch('alert/alert', {
              type: "error",
              message: this.$t("Error creating transaction")
            });
          });
        },
        close(){
          this.$emit("close-status-dialog");
          this.dialogStatus = false;
        },
        getMessage(){
          switch (this.data.status) {
            case "pending":
              return this.$t("DialogStatusMessagePending", { amount: this.data.amount, from: this.data.from, to: this.data.to });
            case "lb_approved":
              if(this.$store.getters['auth/isAdmin']){//Si son usuarios admin de Light Bridges
                return this.$t("DialogStatusMessageReceived", { from: this.data.lb_approved_by, date: this.data.request_timestamp });
              }else{
                return this.$t("DialogStatusMessagePending", { amount: this.data.amount, from: this.data.from, to: this.data.to });
              }
            case "received":
              return this.$t("DialogStatusMessageReceived", { from: this.data.received_by, date: this.data.received_timestamp });
            case "rejected":
              return this.$t("DialogStatusMessageRejected", { from: this.data.rejected_by, date: this.data.rejected_timestamp });
            default:
              return this.$t("DialogStatusMessageUnknow");
          }
        },
        isPending(){
          switch (this.data.status) {
            case "pending":
              return true;
            case "lb_approved":
              //Si son usuarios admin de Light Bridges el status lb_approved, es un estatus final
              if(this.$store.getters['auth/isAdmin']){
                return false;
              }else{
                return true;
              }
            default:
              return false;
          }
        }
      }
  }
</script>

<style scoped>
</style>