<template>
  <v-card elevation="0">
    <v-tabs v-model="tab" align-with-title height="64" grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab key="status">{{ $t('Status') }}</v-tab>
      <v-tab key="planning">{{ $t('Planning') }}</v-tab>
      <v-tab key="operation">{{ $t('Operative') }}</v-tab>
      <v-tab key="proposals">{{ $t('Proposals') }}</v-tab>
    </v-tabs>
    <v-divider />
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item key="status">
          <status :active="tab == 0"/>
        </v-tab-item>
        <v-tab-item key="planning">
          <planning />
        </v-tab-item>
        <v-tab-item key="operational">
          <operational-monitoring :active="tab == 1"/>
        </v-tab-item>
        <v-tab-item key="proposals">
          <v-row class="pa-3">
            <v-col cols="6">
              <all-proposals />
            </v-col>
            <v-col cols="6">
              <all-runs />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import AllProposals from '@/components/proposals/AllProposals.vue'
import AllRuns from '@/components/runs/AllRuns.vue'
import OperationalMonitoring from '@/components/monitoring/Operational.vue'
import Status from '@/components/monitoring/Status.vue'
import Planning from '@/components/monitoring/Planning.vue'

export default {
    components: { AllProposals, AllRuns, OperationalMonitoring, Status, Planning },
    name: 'Monitoring',
    data: function() {
        return {
          tab: 2,
        }
    }
}
</script>