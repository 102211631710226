var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.title))]),(!this.isOrganization)?_c('new-proposal',{on:{"done":_vm.fetch}}):_vm._e(),(this.isOrganization && !!this.organization)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({style:({color: _vm.organization.available_dtos < 0 ? 'red' : 'black'})},on),[_vm._v(_vm._s(_vm.$t('Available DTOs'))+": "+_vm._s(_vm.innerParseFloat(_vm.organization.available_dtos)))])]}}],null,false,3001526982)},[(Object.keys(this.organization.available_dtos_per_year).length === 0)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('No available DTOs')))])]):_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('Available DTOs per year:')))]),_vm._l((this.organization.available_dtos_per_year),function(amount,year){return _c('ul',{key:year},[_c('li',[_vm._v(_vm._s(year)+": "+_vm._s(amount))])])})],2)])],1):_vm._e()],1),_c('v-divider'),_c('v-data-table',{staticClass:"striped cursor-pointer",attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [10, 20, 50, -1]},"no-data-text":_vm.$t('You have no proposals yet'),"options":_vm.options,"server-items-length":_vm.totalItems,"items-per-page":_vm.itemsPerPage},on:{"click:row":function (item) { return _vm.$router.push({name: 'proposal-details', params: { id: item.id, isOrganization: this$1.isOrganization }}); },"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-5 font-weight-bold"},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.justification))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status,"kind":"proposal"}})]}},{key:"item.dto",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.innerParseFloat(item.assigned_dtos))+" | "+_vm._s(_vm.innerParseFloat(item.pending_dtos))+" | "+_vm._s(_vm.innerParseFloat(item.consumed_dtos)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('DTO status')))])])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.created), 'short'))+" ")]}},_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}}),{key:"footer.prepend",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"secondary","icon":""},on:{"click":_vm.fetch}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }