import axios from 'axios'
import jwt_decode from 'jwt-decode'
import i18n from '@/plugins/i18n' 
import { router } from '@/plugins/router'
import { apiPath, tokenRefreshMarginTime, localStorageAuthTokenField } from '@/config'

export const auth = {
    namespaced: true,
    state: {
        accessToken: null,
        refreshToken: null,
        refreshTimeout: null,
        accessTokenExpiration: null,
        user: null,
        auth: null,
    },
    getters: {
        decodedToken: state => {
            if (state.accessToken) {
                return jwt_decode(state.accessToken);
            } else return {}
        },
        loggedIn: state => {
            return state.accessToken != null;
        },
        isAdmin: state => {
            if (state.user) {
                return state.auth.is_superuser || state.auth.is_staff
            } else return false;
        },
        isScientificCommittee: state => {
            if (state.user) {
                return state.user.groups.indexOf("Scientific Committee") > -1
            } else return false;
        },
        isEconomicCommittee: state => {
            if (state.user) {
                return state.user.groups.indexOf("Economic Committee") > -1
            } else return false;
        },
        isOrganizationAdmin: state => {
            if (state.user) {
                return state.user.groups.indexOf("Organization Admin") > -1
            } else return false;
        },
        isDtoManager: state => {
            if (state.user) {
                return state.user.groups.indexOf("DTO Manager") > -1
            } else return false;
        },
    },
    actions: {
        login({ dispatch, commit }, { username, password }) {
            return axios({
                method: "post",
                url: apiPath + "auth/get-token/",
                headers: { 'Content-Type': 'application/json' },
                data: { username: username, password: password }
            }).then(response => {
                commit('setAccessToken', response.data.access);
                commit('setRefreshToken', response.data.refresh);
                dispatch('programRefresh');
                return response
            }).catch(error => {
                if (error.response && (error.response.status == 401)) {
                    dispatch('alert/alert', {
                        message: i18n.t("Wrong credentials. Please try again"),
                        type: "warning",
                    }, { root: true });
                } else {
                    dispatch('alert/alert', {
                        message: error,
                        type: "error",
                    }, { root: true });
                }
                return error.response
            });
        },
        refreshToken({ dispatch, commit, state }) {
            return axios({
                method: "post",
                url: apiPath + "auth/refresh-token/",
                headers: { 'Content-Type': 'application/json' },
                data: { refresh: state.refreshToken }
            }).then(response => {
                commit('setAccessToken', response.data.access);
                dispatch('programRefresh');
            }).catch(error => {
                if (error.response && (error.response.status == 401)) {
                    dispatch('alert/alert', {
                        message: i18n.t("Session has expired. Please log in again"),
                        type: "warning",
                    }, { root: true });
                    dispatch("logout");
                    router.push("login");
                } else {
                    dispatch('alert/alert', {
                        message: error,
                        type: "error",
                    }, { root: true });
                }
            });
        },        
        tryRestoreSession({ dispatch, commit }) {
            let localRefreshToken = localStorage.getItem(localStorageAuthTokenField);
            return axios({
                method: "post",
                url: apiPath + "auth/verify-token/",
                headers: { 'Content-Type': 'application/json' },
                data: { token: localRefreshToken },
            }).then(() => {
                commit("setRefreshToken", localRefreshToken);
                return dispatch('refreshToken');
            }).catch(() => {});
        },
        programRefresh({ dispatch, commit, state }) {
            let timeLeft = state.accessTokenExpiration - new Date();
            commit("setRefreshTimeout", setTimeout(() => {
                dispatch("refreshToken")
            }, timeLeft - tokenRefreshMarginTime));
        },
        logout({ commit }) {
            return commit('logout');
        },
    },
    mutations: {
        setAccessToken(state, accessToken) {
            let decodedToken = jwt_decode(accessToken);
            state.accessToken = accessToken;
            state.accessTokenExpiration = decodedToken.exp * 1000;
            delete decodedToken.exp, decodedToken.type;
            state.user = decodedToken.attrs;
            state.auth = decodedToken.auth;
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
            localStorage.setItem(localStorageAuthTokenField, refreshToken);
        },
        setRefreshTimeout(state, refreshTimeout) {
            state.refreshTimeout = refreshTimeout;
        },
        logout(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.accessTokenExpiration = null;
            state.user = null;
            localStorage.removeItem(localStorageAuthTokenField);
            clearTimeout(state.refreshTimeout);
        },
    }
}