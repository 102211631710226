
<template>
  <v-chip dark v-bind="$attrs" :color="status.color">{{ status.text }}</v-chip>
</template>

<script>
import constants from "@/helpers/constants";

export default {
  name: "StatusChip",
  props: ["value", "kind"],
  data() {
    return {
    }
  },
  computed: {
    status() {
      let _status = null;
      switch (this.kind) {
        case 'proposal':
          _status = constants.PROPOSAL_STATUS_CHOICES.find((obj) => obj.value == this.value);
          break;
        case 'observing-run':
          _status = constants.OBSERVING_RUN_STATUS_CHOICES.find((obj) => obj.value == this.value);
          break;
        case 'observing-block':
          _status = constants.OBSERVING_BLOCK_STATUS_CHOICES.find((obj) => obj.value == this.value);
          break;
        case 'observing-line':
          _status = constants.OBSERVING_LINE_STATUS_CHOICES.find((obj) => obj.value == this.value);
          break;
        case 'telescope':
          _status = constants.TELESCOPE_STATUS_CHOICES.find((obj) => obj.value == this.value);
          break;
        case 'open-close':
          _status = constants.OPEN_CLOSE_CHOICES.find((obj) => obj.value == this.value);
          break;
        case 'operating-inactive':
          _status = constants.OPERATING_INACTIVE_CHOICES.find((obj) => obj.value == this.value);
          break;
      }
      if (!_status) {
        console.warn(`Cannot find ${this.value} in ${this.kind}`)
        return { value: this.value, text: this.value, color: 'gray', };
      } else {
        return _status;
      }
    }
  },
}
</script>

<style scoped>
</style>