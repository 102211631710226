
<template>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <g id="dome">
            <g id="dome-cover">
                <circle class="dome-cover" :cx="dome_cx" :cy="dome_cy" :r="dome_r"></circle>
                <rect :x="dome_cx" :y="dome_cy-dome_r" :width="dome_r" :height="dome_r*2" fill="white" v-if="!domeEastClosed"></rect>
                <rect :x="dome_cx-dome_r" :y="dome_cy-dome_r" :width="dome_r" :height="dome_r*2" fill="white" v-if="!domeWestClosed"></rect>
                <line class="dome-cover-line" :x1="dome_cx" :y1="dome_cy-dome_r" :x2="dome_cx" :y2="dome_cy+dome_r" v-if="domeWestClosed||domeEastClosed"></line>
            </g>
            <circle class="dome-structure" :cx="dome_cx" :cy="dome_cy" :r="dome_r"></circle>
            <circle class="alt" :cx="dome_cx" :cy="dome_cy" :r="dome_r * (30 / 90)"></circle>
            <circle class="alt" :cx="dome_cx" :cy="dome_cy" :r="dome_r * (60 / 90)"></circle>
            <line class="tel-pos" :x1="dome_cx" :y1="dome_cy" :x2="tel_vx" :y2="tel_vy"></line>
            <circle class="tel-pos" :cx="tel_x" :cy="tel_y" :r="tel_r"></circle>
        </g>
        <text x="120" y="45">N</text>
        <text x="120" y="215">S</text>
        <text x="205" y="130">E</text>
        <text x="25" y="130">W</text>
    </svg>
</template>

<script>

export default {
  name: "TelescopeRepresentation",
  props: ["az", "zd", "dome-west-closed", "dome-east-closed" ],
  data() {
    return {
        dome_cx: 125,
        dome_cy: 125,
        dome_r: 70,
        tel_r: 8,
    }
  },
  computed: {
    tel_x() {
        let tel_l = this.dome_r;
        return this.dome_cx - tel_l * (this.zd / 90) * Math.cos(-(this.az + 90) * (Math.PI / 180));
    },
    tel_y() {
        let tel_l = this.dome_r;
        return this.dome_cy + tel_l * (this.zd / 90) * Math.sin(-(this.az + 90) * (Math.PI / 180));
    },
    tel_vx() {
        let tel_l = this.dome_r;
        return this.dome_cx - (tel_l - this.tel_r - 2) * (this.zd / 90) * Math.cos(-(this.az + 90) * (Math.PI / 180));
    },
    tel_vy() {
        let tel_l = this.dome_r;
        return this.dome_cy + (tel_l - this.tel_r - 2) * (this.zd / 90) * Math.sin(-(this.az + 90) * (Math.PI / 180));
    },
    dome_slit_transform() {
        let dome_angle = -this.domeAz;
        return `rotate(${dome_angle},${this.dome_cx},${this.dome_cy})`
    }
  },
}
</script>

<style scoped>
svg {
    width: 250px;
    height: 250px;
}
.dome-structure {
    fill: none;
    stroke: blue; 
    stroke-width: 2;
}
.dome-cover {
    fill: #bbb;
}
.tel-structure {
    fill: #ddd;
    stroke: blue; 
    stroke-width: 2;
}
.dome-cover-line {
    stroke: blue; 
    stroke-width: 2;
}
.dome-slit {
    fill: #ddd;
    stroke: blue; 
    stroke-width: 2;
}

.alt {
    fill: none;
    stroke: blue;
    stroke-width: 1.5;
    stroke-opacity: 0.7;
    stroke-linecap: round;
    stroke-dasharray: 3 6;
}

.tel-pos {
    fill: none;
    stroke: #990000;
    stroke-width: 2;
}
    
.mirror {
    fill: lightgreen;
    stroke: blue; 
    stroke-width: 2;
}
.mirror-cover {
    fill: none;
    stroke: #000; 
    stroke-width: 2;
}
</style>