import Vue from 'vue'
import Vuelidate from 'vuelidate'
import DatetimePicker from 'vuetify-datetime-picker'
import VueApexCharts from 'vue-apexcharts'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import api from '@/plugins/api'
import utils from '@/plugins/utils'
import { router } from '@/plugins/router'
import { store } from '@/store'
import filters from '@/helpers/filters'
import App from '@/App.vue'

 

Vue.config.productionTip = false
Vue.use(api)
Vue.use(utils)
Vue.use(Vuelidate)
Vue.use(DatetimePicker)
Vue.use(VueApexCharts)
for (const [name, filter] of Object.entries(filters)) {
  Vue.filter(name, filter);
}

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
