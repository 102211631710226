<template>
  <my-proposals
    :title="$t('Organization proposals')"
    endPoint="proposals/organization/"
    :isOrganization="this.isOrganization"
  />
  
</template>

<script>
import MyProposals from '../components/proposals/MyProposals.vue'

export default {
    components: { MyProposals },
    name: 'OrgProposals',
    data: function() {
        return {
          isOrganization: true,
        }
    }
}
</script>

<style scoped>
</style>