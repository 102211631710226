
<template>
    <div id="starmap" style="width:300px;height:300px;"></div>
</template>

<script>
export default {
  name: "SkyMap",
  props: ["ra", "dec", ],
  data() {
    return {
        map: null,
    }
  },
  computed: {
    coords() {
      return [this.ra, this.dec]
    }
  },
  mounted() {
    var stuqueryScript = document.createElement('script')
    var virtualskyScript = document.createElement('script')
    stuqueryScript.setAttribute('src', 'https://slowe.github.io/VirtualSky/stuquery.min.js')
    virtualskyScript.setAttribute('src', 'https://slowe.github.io/VirtualSky/virtualsky.min.js')
    document.head.appendChild(stuqueryScript)
    document.head.appendChild(virtualskyScript)
    virtualskyScript.onload = () => {
      this.map = window.S.virtualsky({
        id: 'starmap',
        projection: 'gnomic', 
        ra: this.ra,
        dec: this.dec,
        ground: false,
        fov: 45,
        mouse: false,
        ecliptic: true,
        meridian: true,
        showgalaxy: false,
        showplanets: true,
        showstarlabels: true,
        showdate: false,
        showposition: false,
      });
    }
  },
  watch: {
    coords() {
      if (this.map) {
        this.map.panTo(this.ra, this.dec);
      }
    }
  }
}
</script>

<style>
</style>