
const zeroPad = (num, places) => String(num).padStart(places, '0')

export default {
  default: (value, defaultValue) => {
    return value?value:defaultValue;
  },
  money: (value) => {
    let symbol = "€";
    let decimals = 2;
    return (value != null)?`${parseFloat(value).toFixed(decimals)}\xa0${symbol}`:'';
  },
  percentage: (value) => {
    let decimals = 2;
    return (value > 0.01 | value < -0.01)?`${parseFloat(value).toFixed(decimals)}\xa0%`:'';
  },
  capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  round(number, decimals=2) {
    return Math.round(number * 10**decimals) / 10**decimals;
  },
  filterBlankEntries(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
  },
  decimal2sexagesimal(value, sign=false) {
      if (value === null) {
          return null
      }
      let isNegative = value < 0;
      if (isNegative) {
          value = -value;
      }
      let degrees = Math.floor(value);
      let minutes = Math.floor((value % 1) * 60);
      let seconds = parseFloat((((value * 60) % 1) * 60).toFixed(0));
      return `${sign?(isNegative?'-':'+'):''}${zeroPad(degrees, 2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`
  },
  sexagesimal2decimal(value) {
      let [degrees, minutes, seconds] = value.split(":").map((obj) => parseFloat(obj));
      if (degrees > 0) {
          return degrees + minutes / 60 + seconds / 3600
      } else {
          return degrees - minutes / 60 - seconds / 3600
      }
  }
}
