<template>
  <v-card :elevation="0">
    <v-row>
      <v-col cols="9">
        <v-btn outlined color="primary" class="mx-1">{{ $t('New planification') }}</v-btn>
      </v-col>
      <v-col cols="3">
        <div class="d-flex flex-row-reverse align-center">
          <v-select 
            v-model="timestamp" :items="timestamps"
            item-text="time" item-value="_id"
            append-icon="mdi-clock-outline"
            dense filled hide-details
          />
          <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="shrink" dense filled
                max-width="300" v-model="date" append-icon="mdi-calendar"
                hide-details readonly v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker v-model="date" @input="dateMenu = false" />
          </v-menu>
          <div class="mx-3" v-if="loading">
            <v-progress-circular :size="20" indeterminate />
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="data">
      <plotly ref="schedulePlot" :layout="processedData.plotLayout" :data="processedData.plotTraces" @click="handleClick" />
      <v-row>
        <v-col cols="8">
          {{ selectedRun }}
        </v-col>
        <v-col cols="4">
          <v-data-table :items="data.observing_runs" :headers="observingRunTableHeaders" dense />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      No planning this day
    </div>
  </v-card>
</template>
  
<script>
import * as moment from "moment";
import { Plotly } from "vue-plotly";
import { currentNight, randomColor } from '@/helpers/utils.js'

const interpTime = (t, t0, t1, y0, y1) => {
  return y0 + (y1 - y0) * (new Date(t) - new Date(t0)) / (new Date(t1) - new Date(t0))
}

export default {
  name: "Planning",
  components: { Plotly, },
  data: function() {
    return {
      loading: false,
      lastFetchTime: null,
      dateMenu: false,
      date: currentNight(),
      timestamps: [],
      timestamp: null,
      selectedRun: null,
      data: null,
      observingRunTableHeaders: [
        { text: this.$t('ID'), value: 'id', align: 'center', },
        { text: this.$t('Color'), value: 'color', align: 'center', },
      ],
    }
  },
  methods: {
    fetch() {
      this.loading = true;
      var thisFetchTime = this.lastFetchTime = Date.now()
      return this.$apiRequest('get', `monitoring/plannings/${this.timestamp}/`).then(res => {
        if (this.lastFetchTime <= thisFetchTime) {
          this.data = res.data;
        }
      }).finally(() => { this.loading = false })
    },
    handleClick(e) {
      this.selectedRun = this.processedData.observingRunsMap[e.points[0].data.runId];
    }
  },
  computed: {
    processedData() {
      let data = [];
      let layout = {
        showlegend: false, height: 300,
        hovermode: 'closest', hoverdistance: -1,
        margin: { l: 50, r: 0, b: 30, t: 25 },
        shapes: [],
        annotations: [],
      };
      let telescopesMap = {}, observingRunsMap = {};
      let nTelescopes = this.data.telescopes.length;
      this.data.telescopes.forEach((tel, i) => {
        tel.pos = i;
        tel.domain = [i / nTelescopes, (i+1) / nTelescopes];
        tel.suffix = (i==0)?'':(i+1).toString();
        telescopesMap[tel.name] = tel;
        layout["xaxis"+tel.suffix] = {
          domain: [0, 1],
          anchor: "y"+tel.suffix,
          matches: (i>0)?'x':null,
          showticklabels: i==0,
        }
        layout["yaxis"+tel.suffix] = {
          domain: tel.domain,
          anchor: "x"+tel.suffix,
          range: [0, 90],
          fixedrange: true,
        }
        layout.annotations.push({
          font: { size: 16 }, showarrow: false, text: tel.name, textangle: -90,
          x: 0, y: (tel.domain[0] + tel.domain[1]) / 2,
          xref: "paper", yref: "paper", xshift: -25,
          xanchor: "right", yanchor: "middle",
        })
        let telescopeMeetings = [tel.min_elevation || 20, tel.max_elevation || 85];
        telescopeMeetings.forEach((v) => {
          layout.shapes.push({
            type: 'line', xref: 'paper', yref: 'y'+tel.suffix,
            x0: 0, x1: 1, y0: v, y1: v,
            line: { color: 'red', width: 0.5, dash: 'dot', },
          })
        })
      });
      this.data.observing_runs.forEach((run) => {
        run.color = randomColor();
        observingRunsMap[run.id] = run;
      });
      this.data.blocks.forEach((block) => {
        if (block.telescope) {
          block.tel = telescopesMap[block.telescope];
          block.run = observingRunsMap[block.observing_run];
          block.t0 = moment(block.scheduled_time).toDate().toISOString();
          let [hours, minutes, seconds] = block.duration.split(":").map(parseFloat);
          block.t1 = moment(block.t0).add(hours*60+minutes+seconds/60, 'm').toDate().toISOString();
          block.alt_x = [];
          block.alt_y = [];
          this.data.time_index.forEach((t, i) => {
            if ((t > block.t0) && (t < block.t1)) {
              if (i > 0) {
                var t_prev = this.data.time_index[i-1];
                if (t_prev < block.t0) {
                  var alt0 = interpTime(block.t0, t_prev, t, block.run.alt[i-1], block.run.alt[i]);
                  block.alt_x.push(block.t0);
                  block.alt_y.push(alt0);
                }
              }
              block.alt_x.push(t);
              block.alt_y.push(block.run.alt[i]);
              if (i < (this.data.time_index.length - 1)) {
                var t_next = this.data.time_index[i+1];
                if (t_next > block.t1) {
                  var alt1 = interpTime(block.t1, t, t_next, block.run.alt[i], block.run.alt[i+1]);
                  block.alt_x.push(block.t1);
                  block.alt_y.push(alt1);
                }
              }
            }
          })
          data.push({
            type: 'line', x: block.alt_x, y: block.alt_y,
            hoverinfo: "text", mode: 'lines',
            line: { color: block.run.color, width: 3 },
            xaxis: 'x'+block.tel.suffix, yaxis: 'y'+block.tel.suffix,
            text: `OR${block.run.id}`,
            runId: block.run.id,
          })
          layout.shapes.push({
            type: "rect", xref: 'x', yref: 'paper',
            x0: block.t0, x1: block.t1, y0: block.tel.domain[0], y1: block.tel.domain[1],
            fillcolor: block.run.color, opacity: 0.6, line: { width: 0 },
          });
        }
      })
      return {
        observingRunsMap: observingRunsMap,
        telescopesMap: telescopesMap, 
        plotTraces: data,
        plotLayout: layout,
      }
    }
  },
  watch: {
    date: {
      immediate: true,
      handler() {
        this.$apiRequest('get', 'monitoring/plannings/', {
          params: {
            date: this.date,
          },
        }).then(res => {
          this.timestamps = res.data;
          this.timestamps.forEach((obj) => {
            obj.time = obj.timestamp.slice(11, 16);
          })
          if (this.timestamps.length > 0) {
            this.timestamp = this.timestamps[0]["_id"];
          } else {
            this.timestamp = null;
          }
        })
      }
    },
    timestamp() {
      if (this.timestamp) {
        this.fetch();
      } else {
        this.data = null;
      }
    }
  },
}
</script>

<style scoped>
</style>  