<template>
  <v-dialog v-model="dialog" width="400">
    <template #activator="{ on: dialog }">
      <slot name="activator" :on="dialog" />
    </template>
    <v-card :loading="loading">
      <v-card-title class="text-h5 mb-5">{{ $t("Delete proposal") }}</v-card-title>
      <v-card-text>
        {{ $t("Are you sure you want to cancel this proposal?") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            class="ma-2 white--text"
            @click="deleteProposal()"
          >
            Delete
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "DeleteProposal",
  props: [ "obj" ],
  data: function() {
    return {
      dialog: false,
      loading: false,
    }
  },
  methods: {
    deleteProposal() {
      this.loading = true;
      this.$apiRequest('delete', `proposals/${this.obj.id}/delete/`).then(() => {
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Proposal succesfully deleted")
        });
        this.loading = false;
        this.dialog = false;
        this.$emit("done");
      })
    }
  },
}
</script>

<style scoped>
</style>