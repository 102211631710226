<template>
  <my-proposals 
    :title="$t('My proposals')"
    endPoint="proposals/mine/"
  />
</template>

<script>
import MyProposals from '@/components/proposals/MyProposals.vue'

export default {
    components: { MyProposals },
    name: 'Home',
    data: function() {
        return {
        }
    }
}
</script>

<style scoped>
</style>