<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <span>{{ $t('Proposals') }}</span>
    </v-card-title>
    <v-data-table :loading="loading" :items="items" class="striped" :headers="headers"
      :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
      @click:row="(item) => $router.push({name: 'proposal-details', params: { id: item.id }})"
      :options.sync="options" :server-items-length="totalItems" :items-per-page="itemsPerPage" >
      <template #[`item.title`]="{ item }">
        <div class="item-title">{{ item.title }}</div>
      </template>
      <template #[`item.status`]="{ item }">
        <status-chip :value="item.status" kind="proposal" />
      </template>
      <template #[`item.created`]="{ item }">
        {{ $d(new Date(item.created), 'short') }}
      </template>
      <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template #[`footer.prepend`]>
        <v-btn small color="secondary" icon @click="fetch"><v-icon small>mdi-refresh</v-icon></v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import StatusChip from '@/components/utils/StatusChip.vue';

export default {
  name: "AllProposals",
  components: { StatusChip },
  data: function() {
    return {
      items: [],
      headers: [
        { value: 'id', text: '#', align: 'center', sortable: true, divider: true, width: 80, },
        { value: 'title', text: this.$i18n.t('Description'), align: 'left', sortable: true },
        { value: 'applicant', text: this.$i18n.t('Applicant'), align: 'center', sortable: true },
        { value: 'status', text: this.$i18n.t('Status'), align: 'center', sortable: true },
        { value: 'created', text: this.$i18n.t('Created'), align: 'right', sortable: false },
      ],
      loading: false,
      totalItems: 0,
      itemsPerPage: 0,
      lastFetchTime: null,
      selectedItem: null,
      options: {},
    }
  },
  computed: {
    filterset() {
      let orderings = [];
      if (this.options.sortBy) {
        for (var i=0;i<this.options.sortBy.length;i++) {
          orderings.push((this.options.sortDesc[i]?'':'-') + this.options.sortBy[i]);
        }
      }
      return {
        ...this.filters,
        ordering: orderings.join(","),
        page: this.options.page,
        page_size: this.options.itemsPerPage,
      }
    }
  },
  methods: {
    fetch() {
      this.loading = true;
      var thisFetchTime = this.lastFetchTime = Date.now()
      this.$apiRequest('get', "proposals/", {
        params: this.filterset,
      }).then(res => {
        if (this.lastFetchTime <= thisFetchTime) {
          this.items = res.data.results;
          this.totalItems = res.data.count;
          this.itemsPerPage = res.data.results.length;
        }
      }).finally(() => (this.loading = false))
    },
  },
  watch: {
    filterset: {
      handler (newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          this.$nextTick(this.fetch);
        }
      },
    },
  }, 
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
.item-title {
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
  text-overflow: ellipsis;
}
</style>
