<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title>{{ $t('DTO management') }}</v-card-title>
    <v-divider class="mb-3" />
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <v-card class="my-3" color="primary lighten-5" elevation="0">
            <v-form lazy-validation ref="formDTO">
              <v-card-title class="text-overline pb-0">{{ $t('DTO acquisition and regulation') }}</v-card-title>
              <v-card-text>
                <v-row>
                  <!-- Organizations List -->
                  <v-col cols="6">
                    <v-autocomplete 
                      outlined 
                      :label="$t('Organization')" 
                      v-model="form.to_organization" 
                      :items="this.getOrganizationsListName()" 
                      clearable
                      search
                    >
                      <template v-slot:append>
                        <v-icon color="primary">mdi-account-group</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Year List -->
                  <v-col cols="6">
                    <v-select outlined :label="$t('year')" v-model="year" :items="yearsArray" clearable>
                      <template v-slot:append>
                        <v-icon color="primary">mdi-calendar-blank</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Action List -->
                  <v-col cols="6">
                    <v-select outlined :label="$t('Action to do')" v-model="form.type" :items="this.getActions()" clearable >
                      <template v-slot:append>
                        <v-icon color="primary">mdi-file-tree </v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <!-- DTO's Amoung -->
                  <v-col cols="6" v-if="this.isVisible()">
                    <v-text-field 
                      outlined 
                      :label="$t('DTO')" 
                      v-model.number="form.amount" 
                      type="number"
                      suffix="DTO" 
                      step="0.01" 
                      :min="this.minDtoValue"
                      :rules="rules"
                      :hint="this.getHint()"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Observations -->
                  <v-col cols="12">
                    <v-textarea
                      clearable
                      clear-icon="mdi-close-circle"
                      :label="$t('Observations')"
                      placeholder="Write some observation"
                      v-model="form.observations"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Button -->
                  <v-col cols="12" class="text-right" v-if="this.isVisible()">
                    <v-btn color="primary" outlined @click="submit">{{ $t('Save') }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
      </v-col>
    </v-row>
    <!-- Botón Temporal para caducar DTO's -->
    <!-- <v-row>
      <v-col cols="12">
        <v-btn color="primary" outlined @click="elapsed">{{ $t('Elapsed') }}</v-btn>
      </v-col>
    </v-row> -->
  </v-card>
</template>
<script>

const actions = {
      ADQUISITION: "adquisition",
      REGULARIZATION: "regularization",

};
export default {
    name: 'DtoManagement',    
    watch: {
      'form.type'(newValue) {
        this.setMinDtoValue();
        this.activateRules(newValue);
      },
      'form.to_organization'() {
        if(this.year !==null){
          this.getNumDtoAvailable();
        }
      },
      year(newVal) {
        if(this.year !==null){
          this.form.validity = newVal+"-12-31T00:00:00Z";
          this.getNumDtoAvailable();
        }
      },
    },
    data: function() {
        return {
          aux:{
            year: null,
            name: null,
          },
          loading: false,
          organizations: null,
          actions:[actions.ADQUISITION, actions.REGULARIZATION],
          form: {
            to_organization: null,
            from_organization: null,
            amount: 0,
            validity: null,
            type: null,
            observations: null,
            transaction_by: null
          },
          year: null,
          dtoAvailable: 0,
          minDtoValue: null,
          rules: [],
        }
    },
    created(){
      this.fetchOrganizations();
      this.form.transaction_by = this.$store.getters["auth/decodedToken"].user_id;
    },
    computed: {
      yearsArray(){
        const currentYear = new Date().getFullYear();
        return Array.from({ length: 5 }, (_, i) => currentYear + i);
      },
    },
    methods: {
      fetchOrganizations(){
        this.loading = true;
        this.$apiRequest('get', 'organizations/').then((res) => {
          this.organizations = res.data;
        }).finally(() => {
          this.loading = false;
        })
      },
      getOrganizationsListName() {
        if(this.organizations !== null){
          return this.organizations.map(org => org.name);
        }
        return [];
      },
      getOrganizationsId(name) {
          return this.organizations.filter(item =>item.name==name).map(org => org.id).reduce((accum, currVal) => accum+ currVal, 0);
      },
      getActions() {
        return this.actions;
      },
      submit() {
        if (this.$refs.formDTO && this.$refs.formDTO.validate()) {
          this.loading = true;

          const auxForm = { ...this.form };
          auxForm.to_organization = this.getOrganizationsId(this.form.to_organization);
          this.$apiRequest('post', "transactions/", { 
            data: auxForm,
          }).then((data) => {
            if(typeof data != 'undefined'){
              this.$store.dispatch('alert/alert', {
                type: "info",
                message: this.$t("Operation completed successfully")
              });
            }else{
              this.$store.dispatch('alert/alert', {
                type: "error",
                message: this.$t("Operation error")
              });
            }
            this.loading = false;
          }).finally(() => {
            this.resetForm();
          })
        }
      },
      isVisible(){
        return !!this.form.to_organization & !!this.year & !!this.form.type;
      },
      activateRules(action) {
        let auxRules = [];
        let required = v => v != null || this.$t("The field is required");
        let numeric = v =>
          (v != null && /^-?\d+(\.\d*)?$/.test(v)) ||
          this.$t("The field must be numeric");
        let positive = v =>
          (v != null && v>0) ||
          this.$t("The field must be positive");
        let min = v =>
          (v != null && v>=this.minDtoValue) ||
          this.$t("The organization do not have", { value: this.dtoAvailable });
        switch (action) {
          case actions.ADQUISITION:
            auxRules.push(required, positive, numeric);
            break;
          case actions.REGULARIZATION:
            auxRules.push(required, numeric, min);
            break;
        }
        Object.assign(this.rules, auxRules);
      },
      getNumDtoAvailable() {
        this.dtoAvailable = 0;
        if (this.year !== null && this.form.to_organization !== null) {
          // this.$apiRequest('get', `organizations/available/?year=${this.year}&name=${this.form.to_organization}`, {}, [404, 403]).then(res => {
          this.$apiRequest('get', 'organizations/available/', { params: { year: this.year, name: this.form.to_organization } }, [404, 403])
            .then(res => {
              this.dtoAvailable = res.data;
              console.log("[abe] - ", this.form.to_organization, ":", this.year, " - getNumDtoAvailable: ", this.dtoAvailable);
            }).finally(() => {
              this.setMinDtoValue();
            })
        }
      },
      setMinDtoValue(){
        switch (this.form.type) {
          case actions.ADQUISITION:
          this.minDtoValue = 0.01;
            break;
          case actions.REGULARIZATION:
            this.minDtoValue = -this.dtoAvailable;
            break;
        }
      },
      resetForm(){
        this.year = null;
        this.form.to_organization = null;
        this.form.from_organization = null;
        this.form.amount = 0;
        this.form.validity = null;
        this.form.type = null;
        this.form.observations = null;
      },
      getHint(){
        switch (this.form.type) {
          case actions.ADQUISITION:
            return "";
          case actions.REGULARIZATION:
            return this.$t('DTO available', { value: this.dtoAvailable });
        }
        return "";
      },
      elapsed(){
        let date = new Date();
        console.log("[abe] - ELAPSED DTOS'S year:", date.getFullYear());
        this.loading = true;
        this.$apiRequest('post', 'proposals/elapsed/', { params: { year: "2023" } }, [404, 403])
          .then((res) => {
            console.log("[abe] -> ",res.data.message);
        }).finally(() => {
          this.loading = false;
        })
      },
    }
}
</script>

<style scoped>
</style>