var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('div',[_c('v-select',{attrs:{"items":_vm.headers,"label":_vm.$t('Visible Columns'),"multiple":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 4)+" others)")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1),_c('v-data-table',{staticClass:"striped",attrs:{"headers":_vm.showHeaders,"footer-props":{ 'items-per-page-options': [10, 20, 50] },"items":_vm.filteredItemsTable,"search":_vm.filters.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Search'),"placeholder":_vm.$t('Search by from/to org., type, validity or amount'),"append-icon":"mdi-magnify","filled":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-datetime-picker',{attrs:{"date-format":"yyyy-MM-dd","time-format":"HH:mm","label":_vm.$t('Since') + ' ' + _vm.$t('Date'),"text-field-props":{ hideDetails: true, filled: true, dense: true, appendIcon: 'mdi-calendar' },"date-picker-props":{ noTitle: true, },"time-picker-props":{ noTitle: true, format: '24hr' }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-calendar")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock")])]},proxy:true},{key:"actions",fn:function(ref){
var parent = ref.parent;
return [_c('v-btn',{attrs:{"text":"","color":"secondary"},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Clear')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":parent.okHandler}},[_vm._v(_vm._s(_vm.$t('Ok')))])]}}]),model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-datetime-picker',{attrs:{"date-format":"yyyy-MM-dd","time-format":"HH:mm","label":_vm.$t('Until') + ' ' + _vm.$t('Date'),"text-field-props":{ hideDetails: true,  filled: true, dense: true, appendIcon: 'mdi-calendar' },"date-picker-props":{ noTitle: true, },"time-picker-props":{ noTitle: true, format: '24hr' }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-calendar")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock")])]},proxy:true},{key:"actions",fn:function(ref){
var parent = ref.parent;
return [_c('v-btn',{attrs:{"text":"","color":"secondary"},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Clear')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":parent.okHandler}},[_vm._v(_vm._s(_vm.$t('Ok')))])]}}]),model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.itemType,"label":_vm.$t('Type'),"filled":"","dense":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}}]),model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1)],1)]},proxy:true},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(header.text))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(_vm.getValueType(item.type)))])]}},{key:"header.validity",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(header.text))])]}},{key:"item.validity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(_vm.getYear(item.validity)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }